import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { User, UserLoginInput, UserSignupInput } from '../../@types/Auth'

const initialState: UserSignupInput[] = [
  {
    email: 'teste@email.com',
    name: 'teste',
    phoneNumber: '0000000',
    username: 'teste',
    password: '123'
  }
]

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: (state,action: PayloadAction<UserSignupInput>) => {
      let stateAux = state
      stateAux.push(action.payload)
      state = stateAux
    },
    getByEmailPassword: (state,action: PayloadAction<UserLoginInput>) => {
      return state.filter(x => x.email === action.payload.username && x.password === action.payload.password)
    },
  }
})

export const {addUser, getByEmailPassword} = usersSlice.actions
export default usersSlice.reducer
