import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { Status } from '../../../interfaces/status'
import { DataRTKReturnList, DataRTKReturn } from '../../../interfaces/dataRTKReturn'

const statusApi = createApi({
  reducerPath: 'statusApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/status` }),
  tagTypes: ['status'],
  endpoints: (builder) => ({
    getStatusAll: builder.mutation<DataRTKReturnList<Status>, undefined>({
      query: () => ({
        url: `/get-all`,
        body: JSON.stringify({
          name: null,
          activated: true
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['status']
    }),
    getStatusIdByName: builder.mutation<DataRTKReturnList<Status>, string>({
      query: (name:string) => ({
        url: `/get-all`,
        body: JSON.stringify({
          name: name,
          activated: true
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      // transformResponse: (rawResult: {result: {status:Status}}) => {
      //   return rawResult.result.status.Id
      // },
      invalidatesTags: ['status']
    }),
  })
})

export default statusApi
