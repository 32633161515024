import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { City } from '../../../interfaces/city'
import { DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { DataRTKReturn } from '../../../interfaces/dataRTKReturn'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { IResponseError, IResponseErrorNotFound } from '../interfaces/IResponseApi'

const cardEnterpriseIdsApi = createApi({
  reducerPath: 'cardEnterpriseIdsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo` }),
  tagTypes: ['cardEnterprisesIds'],
  endpoints: (builder) => ({
    getEnterprisesCardsByIds: builder.mutation<DataRTKReturnList<Enterprise2>, string[]>({
      query: (listIds) => ({
        url: `/enterprise-card-by-ids`,
        body: JSON.stringify({
          ids: listIds
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['cardEnterprisesIds'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    })
  })
})

export default cardEnterpriseIdsApi
