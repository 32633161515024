import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IUserProps } from "./interfaces/IUserStore"

const initialState: IUserProps = {
  userId: '',
  code: ''
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeUserId: (state,action: PayloadAction<string>) => {
      state.userId = action.payload
    },
    changeCode: (state,action: PayloadAction<string>) => {
      state.code = action.payload
    }
  }
})

export default userSlice.reducer
export const {changeUserId, changeCode} = userSlice.actions
