export const codeSearchUrl = 'search='
export const codeCityUrl = 'city='
export const codeStateUrl = 'state='
export const codeUfUrl = 'uf='
export const codeStageUrl = 'stage='
export const codefamilyincomeUrl = 'familyincome='
export const codepriceMaxUrl = 'priceMaximumValue='
export const codepriceMinUrl = 'priceMinimumValue='
export const codeNumberRooms = "numberRooms="
export const codeFulltext = "fullText="
