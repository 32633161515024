import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = "inicio"

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    changeSearch: (state,action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const {changeSearch} = searchSlice.actions
export default searchSlice.reducer
