import React from 'react'
import * as RadixAccordion from '@radix-ui/react-accordion'
import clsx from 'clsx'
// import { CaretDown } from 'phosphor-react'
import { forwardRef, Ref } from 'react'

const AccordionRoot = forwardRef(
  (
    {
      children,
      className = 'w-full rounded bg-off-white shadow',
      ...props
    }: RadixAccordion.AccordionSingleProps | RadixAccordion.AccordionMultipleProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    return (
      <RadixAccordion.Root ref={forwardedRef} {...props} className={clsx('', className)}>
        {children}
      </RadixAccordion.Root>
    )
  }
)

const AccordionItem = forwardRef(
  (
    { children, className, ...props }: RadixAccordion.AccordionItemProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    return (
      <RadixAccordion.Item
        ref={forwardedRef}
        {...props}
        className={clsx(
          className,
          'overflow-hidden mt-px first:mt-0 first:rounded-t last:rounded-b focus-within:relative focus-within:z-10'
        )}
      >
        {children}
      </RadixAccordion.Item>
    )
  }
)

const AccordionTrigger = forwardRef(
  (
    { children, className, ...props }: RadixAccordion.AccordionTriggerProps,
    forwardedRef: Ref<HTMLButtonElement>
  ) => {
    return (
      <RadixAccordion.Header className="flex">
        <RadixAccordion.Trigger
          className={clsx(
            className,
            'bg-transparent px-5 h-11 flex-1 flex items-center justify-between text-base font-semibold leading-none  shadow bg-white hover:bg-off-white [&[data-state=open]>svg]:rotate-180 [&>svg]:transition-all [&[data-state=open]>svg]:duration-200'
          )}
          {...props}
          ref={forwardedRef}
        >
          {children}
          {/* <CaretDown weight="bold" size={14} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            fill='currentColor'
            viewBox="0 0 256 256"
          >
            <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
          </svg>
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
    )
  }
)

const AccordionContent = forwardRef(
  (
    { children, className, ...props }: RadixAccordion.AccordionContentProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    return (
      <RadixAccordion.Content
        className={clsx(
          className,
          'state-open:animate-accordion-down state-closed:animate-accordion-up'
        )}
        {...props}
        ref={forwardedRef}
      >
        <div className={className}>{children}</div>
      </RadixAccordion.Content>
    )
  }
)

AccordionRoot.displayName = 'Accordion.Root'
AccordionItem.displayName = 'Accordion.Item'
AccordionTrigger.displayName = 'Accordion.Trigger'
AccordionContent.displayName = 'Accordion.Content'

export const Accordion = {
  Root: AccordionRoot,
  Item: AccordionItem,
  Trigger: AccordionTrigger,
  Content: AccordionContent
}
