import { createSlice } from "@reduxjs/toolkit"
import { ICityProps } from "./interfaces/ICityStore"

const initialState: ICityProps = {}

const citiesFetchSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {}
})

export default citiesFetchSlice.reducer
