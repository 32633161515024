import { Suspense, lazy, useEffect, useLayoutEffect, useState } from 'react'
import { AuthProvider } from './hooks/useAuth'
import clsx from 'clsx'
import { Cookies } from 'react-cookie'
import Logo from './assets/glemo-logo.webp'
import { DATE_ENTRY, DEVICE_ID, LOCATIONS } from './constants/storageNames'
import { isMobile, isValidStr } from './common_functions'
const AppRouter = lazy(() => import('./routes'))
import React from 'react'
import { Home2 } from './pages/Home/index2'
import { Header } from '@radix-ui/react-accordion'
import HeaderMobile from './components/HeaderMobile'
import SectionBestPropertiesFullText from './components/Sections/SectionBestPropertiesFullText'
import SectionBestPropertiesFullTextMobile from './components/Sections/SectionBestPropertiesFullTextMobile'
import CarouselConstructionCompanies from './components/Carousels/CarouselConstructionCompanies'
import SectionEcosystemAd from './components/Sections/SectionEcosystemAd'
import SkeletonLoading from './components/common/SkeletonLoading'
import SectionSearchGlemo from './components/Sections/SectionSearchGlemo'

export default function App() {
  const location = window.location
  const cookies = new Cookies()
  const [allowLocals, setAllowLocals] = useState(false)
  const [loadingComponents, setLoadingComponents] = useState(false)

  const isValidCookies = () => {
    return cookies.get(LOCATIONS) != undefined && cookies.get(LOCATIONS) != 'undefined'
  }

  const isValidPathname = () => {
    return location.pathname != '/entrar' && location.pathname != '/registrar'
  }

  // useEffect(() => {
  //   if (allowLocals && isSuccessStates) {
  //     getLocalidades()
  //   }
  // }, [allowLocals, isLoadingStates, isSuccessStates, isErrorStates])

  const handleLoad = () => {
    // Adicionar os scripts após o carregamento completo da página

    //alert('Invoked handle load')

    const head = document.head
    const body = document.body

    const script1 = document.createElement('script')
    script1.defer = true
    script1.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }`
    head.appendChild(script1)

    const script2 = document.createElement('script')
    script2.defer = true
    script2.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WQ39T96');`
    head.appendChild(script2)

    const linkTiny = document.createElement('link')
    // <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.3/tiny-slider.css" />
    linkTiny.rel = 'stylesheet'
    linkTiny.href = 'https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.3/tiny-slider.css'
    head.appendChild(linkTiny)

    const script3 = document.createElement('script')
    script3.type = 'application/ld+json'
    script3.defer = true
    script3.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "glemO",
      "alternateName": "glemo Imóveis",
      "url": "https://www.glemo.com.br",
      "logo": "https://www.glemo.com.br/assets/glemo-logo-2-c14faa1a.png",
      "sameAs": [
        "https://www.instagram.com/portalglemo/",
        "https://www.facebook.com/portalglemo"
      ]
    }`

    head.appendChild(script3)

    const adopt = document.createElement('meta')
    adopt.name = 'adopt-website-id'
    adopt.content = '2c1b73d6-dc7b-4ab5-845a-9797ac244096'

    head.appendChild(adopt)

    const scriptAdopt = document.createElement('script')
    scriptAdopt.defer = true
    scriptAdopt.src =
      '//tag.goadopt.io/injector.js?website_code=2c1b73d6-dc7b-4ab5-845a-9797ac244096'
    scriptAdopt.className = 'adopt-injector'

    head.appendChild(scriptAdopt)

    //   <link rel="preconnect" href="https://fonts.googleapis.com">
    // <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
    const linkfontsgoogleapi = document.createElement('link')
    linkfontsgoogleapi.rel = 'preconnect'
    linkfontsgoogleapi.href = 'https://fonts.googleapis.com'
    const linkfontsgtatic = document.createElement('link')
    linkfontsgtatic.rel = 'preconnect'
    linkfontsgtatic.href = 'https://fonts.gstatic.com'
    linkfontsgtatic.crossOrigin = 'true'

    head.appendChild(linkfontsgtatic)

    // <link
    // href="https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap"
    // rel="stylesheet">
    // const linkFont = document.createElement('link')
    // linkFont.href =
    //   'https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap'
    // linkFont.rel = 'stylesheet'
    // head.appendChild(linkFont)

    // FIM DE CONTEUDOS HEAD

    // const script4 = document.createElement('script')
    // script4.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1'
    // script4.defer = true
    // body.appendChild(script4)

    const script5 = document.createElement('script')
    script5.innerHTML = `document.addEventListener('df-messenger-opened', function (event) {
      let adPolicy = document.querySelector('#adopt-controller-button')
      if (adPolicy != undefined && window.screen.width < 769) {
        adPolicy.style.setProperty('display', 'none', 'important')
      }
    })
    `
    body.appendChild(script5)

    setLoadingComponents(true)
  }

  useEffect(() => {
    if (!isMobile()) {
      window.addEventListener('load', handleLoad)
      return () => {
        window.removeEventListener('load', handleLoad)
      }
    }
  }, [])

  // useEffect(() => {
  //   if(isMobile()){
  //     if (document.readyState === 'complete') {
  //       setTimeout(() => {
  //         handleLoad()
  //       }, 2500);
  //     }
  //   }
  // }, [document.readyState])

  useEffect(() => {
    if(isMobile() && location.pathname == "/"){
      const waitForImagesToLoad = (): Promise<void> => {
        const images: HTMLImageElement[] = Array.from(document.images) as HTMLImageElement[];
        if (images.length === 0) {
          return Promise.resolve();
        }
        const promises = images.map(img => {
          if (img.complete) {
            return Promise.resolve();
          }
          return new Promise<void>(resolve => {
            img.onload = img.onerror = () => resolve();
          });
        });
        return Promise.all(promises).then(() => {});
      };

      waitForImagesToLoad().then(() => {
        // alert('Página completamente carregada com todas as imagens.');
        setTimeout(() => {
          handleLoad()
        }, 5000);
      });
    }else setLoadingComponents(true)
  }, []);

  useEffect(() => {
    if (isValidPathname()) {
      setAllowLocals(true)
    }
    addDateEntry()
    addDeviceId()
  }, [location.pathname])

  const addDateEntry = () => {
    let dateEntryAux = sessionStorage.getItem(DATE_ENTRY)
    if (!isValidStr(dateEntryAux)) {
      sessionStorage.setItem(DATE_ENTRY, new Date().toISOString())
    }
  }

  const randomNumberInRange = () => {
    return Math.floor(Math.random() * (10000 - 0 + 1)) + 0
  }

  const addDeviceId = () => {
    let deviceId = cookies.get(DEVICE_ID)
    if (deviceId == undefined || deviceId == null || deviceId == 0) {
      cookies.set(DEVICE_ID, randomNumberInRange())
    }
  }

  return (
    <div className={`h-full app-root`}>
      <AuthProvider>
        <Suspense fallback={<div id="loadingBox"></div>}>
          {loadingComponents ? (
            <AppRouter />
          ) : (
            <>
              {location.pathname == '/' ? (
                <>
                  <HeaderMobile />
                  {!isMobile() ? (
                    <SectionBestPropertiesFullText />
                  ) : (
                    <SectionBestPropertiesFullTextMobile />
                  )}
                  <div className="mt-20 container-responsive-view mb-4">
                    <SectionEcosystemAd />
                  </div>

                  <div id="loadingBox"></div>
                </>
              ) : (
                <>
                  <div id="loadingBox"></div>
                  <div className="mt-4 text-center">
                    <img alt="Glemo Logo" src={Logo} className="w-[150px] m-auto" />
                  </div>
                </>
              )}
            </>
          )}
        </Suspense>
      </AuthProvider>
    </div>
  )
}
