import React, { useState } from 'react'
import { Suspense, lazy, memo } from 'react'

import BgOrange from '../../../assets/banner-orange-ad.webp'
import BgBlue from '../../../assets/banner-blue-ad.webp'
import { isMobile } from '../../../common_functions'

const SkeletonLoading = lazy(() => import('../../common/SkeletonLoading'))

interface IBannerHome {
  imgUrl: string
  href: string
}

const listImgs: IBannerHome[] = [
  {
    imgUrl: BgOrange,
    href: '/o-glemo'
  },
  {
    imgUrl: BgBlue,
    href: '/construtoras-e-incorporadoras'
  }
]

function SectionEcosystemAd() {
  const [selectedImgIndex, setSelectedImgIndex] = useState<number>(0)

  const handleChangeImgCarousel = (index: number) => {
    setSelectedImgIndex(index)
  }

  return (
    <>
      {isMobile() ? (
        <>
          <a aria-label='Saiba mais sobre' href={listImgs[selectedImgIndex].href}>
            <img src={listImgs[selectedImgIndex].imgUrl} alt='Banner Carrosel Home' className="w-[320px] lg:w-full" />
          </a>

          <div className="-mt-[40px] ms-5">
            {listImgs.map((item, index) => {
              return (
                <button
                  value='Trocar Banner'
                  className={
                    'rounded-full bg-[#C4C4C4] mx-2 w-[35px] h-[3px] ' +
                    (index != selectedImgIndex ? 'opacity-40' : '')
                  }
                  onClick={() => handleChangeImgCarousel(index)}
                ></button>
              )
            })}
          </div>
        </>
      ) : (
        <>
          <div className="lg:grid lg:grid-cols-2 gap-10">
            {listImgs.map((item) => {
              return (
                <Suspense fallback={<SkeletonLoading height={250} />}>
                  <a href={item.href}>
                  <img src={item.imgUrl} />
                  </a>
                </Suspense>
              )
            })}
          </div>
        </>
      )}
      {/* <div className="lg:grid lg:grid-cols-2 gap-10">
        <div>
          <Suspense fallback={<SkeletonLoading  height={250}/>}>
            <CardRoundedIDL
              title="Um ecossistema construído pensando em você!"
              description="Encontre o imóvel perfeito de maneira intuitiva e eficiente."
              labelLink="SAIBA MAIS SOBRE O GLEMO"
              link="/o-glemo"
              bg={BgEcosystem}
            />
          </Suspense>
        </div>
        <div className="mt-5 lg:mt-0">
          <Suspense fallback={<SkeletonLoading height={250}/>}>
            <CardRoundedIDL
              title="Anuncie com quem é referência"
              description="Ofereça a melhor experiência de compras e seja protagonista no mercado imobiliário"
              labelLink="CONHEÇA O GLEMO PARA ANUNCIANTES"
              link="/construtoras-e-incorporadoras"
              orderTxtRight
              bg={BgAd}
            />
          </Suspense>
        </div>
      </div> */}
    </>
  )
}
export default memo(SectionEcosystemAd)
