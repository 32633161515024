import React from 'react'
import { Button } from '../common'
import { useState } from 'react'
import { BusqueCidadeEstadoBairro } from '../BusqueCidadeEstadoBairro/index'
import { cleanSelectedArgumentsForAutocomplete, isValidStr } from '../../common_functions'
import SkeletonLoading from '../common/SkeletonLoading'
import ModalFilter from '../Modais/ModalFilter'
import {
  FILTER_BATHROOMS,
  FILTER_DIFFERENTIALS,
  FILTER_LIST_STATUS,
  FILTER_NUMBER_BEDROOMS,
  FILTER_PARKING_SPACES,
  FILTER_SUIT,
  FILTER_area_MAX,
  FILTER_area_MIN,
  FULL_TEXT
} from '../../constants/storageNames'

interface Props {
  isMobile?: boolean
}

export function SearchBar({ isMobile = false }: Props) {
  const [showModalFilter, setShowModalFilter] = useState(false)

  const handleSearch = () => {
    let input =
      document.querySelectorAll('#enderecoBuscaEmpreendimento').length > 1
        ? document.querySelectorAll('#enderecoBuscaEmpreendimento')[1]
        : document.querySelectorAll('#enderecoBuscaEmpreendimento')[0]

    let fullText = ''
    if (window.screen.width >= 768) {
      fullText = (document.querySelector('#enderecoBuscaEmpreendimento') as HTMLInputElement).value
    } else {
      fullText = (input as HTMLInputElement).value
    }

    fullText = getFullTextFilter(!isValidStr(fullText) ? 'Empreendimentos ' : fullText)

    let searchUrl = ''
    if (isValidStr(fullText)) {
      searchUrl = `?search=enterprises&fullText=${fullText}`
    }
    cleanSelectedArgumentsForAutocomplete()
    window.location.href = `/busca/${searchUrl}`
  }

  const getFullTextFilter = (argument: string) => {
    const filterNUMBER_BEDROOMS = sessionStorage.getItem(FILTER_NUMBER_BEDROOMS)
    const filterBATHROOMS = sessionStorage.getItem(FILTER_BATHROOMS)
    const filterPARKING_SPACES = sessionStorage.getItem(FILTER_PARKING_SPACES)
    const filterSUIT = sessionStorage.getItem(FILTER_SUIT)
    const filterarea_MIN = sessionStorage.getItem(FILTER_area_MIN)
    const filterarea_MAX = sessionStorage.getItem(FILTER_area_MAX)
    const filterDIFFERENTIALS = sessionStorage.getItem(FILTER_DIFFERENTIALS)
    const filterSTATUSLIST = sessionStorage.getItem(FILTER_LIST_STATUS)

    let fullText = argument
    if (isValidStr(filterSTATUSLIST?.toString())) {
      fullText += ' ' + filterSTATUSLIST?.replaceAll(',', ' ou ') + ''
    }
    if (isValidStr(filterNUMBER_BEDROOMS?.toString())) {
      fullText += ', ' + parseInt(filterNUMBER_BEDROOMS ?? '') + ' quartos'
      if (filterBATHROOMS?.includes('+')) {
        fullText += ' ou mais'
      }
    }
    if (isValidStr(filterBATHROOMS?.toString())) {
      fullText += ', ' + parseInt(filterBATHROOMS ?? '') + ' banheiros'
      if (filterBATHROOMS?.includes('+')) {
        fullText += ' ou mais'
      }
    }
    if (isValidStr(filterPARKING_SPACES?.toString())) {
      fullText += ', ' + parseInt(filterPARKING_SPACES ?? '') + ' vagas'
      if (filterBATHROOMS?.includes('+')) {
        fullText += ' ou mais'
      }
    }
    if (isValidStr(filterSUIT?.toString())) {
      fullText += ', ' + parseInt(filterSUIT ?? '') + ' suítes'
      if (filterBATHROOMS?.includes('+')) {
        fullText += ' ou mais'
      }
    }
    if (isValidStr(filterarea_MIN?.toString())) {
      fullText += ', no mínimo ' + filterarea_MIN + ' m2'
    }
    if (isValidStr(filterarea_MAX?.toString())) {
      fullText += ', no máximo ' + filterarea_MAX + ' m2'
    }
    if (isValidStr(filterDIFFERENTIALS?.toString())) {
      fullText += ' com ' + filterDIFFERENTIALS?.replace(",", ", ").toLowerCase()
    }
    return fullText
  }

  const handleFilter = () => {
    const filterHTML = document.querySelector(
      isMobile ? '#sectionFilterMobile' : '#sectionFilter'
    ) as HTMLElement
    if (filterHTML != undefined) {
      filterHTML.classList.toggle('hidden')
    }
  }

  return (
    <>
      <div className="flex max-w-lg gap-4 search-bar m-auto">
        <>
          <BusqueCidadeEstadoBairro />
        </>
        <Button
          variant="secondary"
          aria-label="Filtrar"
          id='openSectionFilter'
          value="Filtrar"
          className={isMobile ? 'order-2' : ''}
          onClick={handleFilter}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.78573 9.02344H0.928589M18.0714 14.7377L15.2143 14.7377M18.0714 3.30915L10.6429 3.30916M8.35716 3.30915L0.92859 3.30915M6.07145 9.02344L18.0714 9.02344M12.9286 14.7377H0.92859M12.9286 12.452V17.0234L15.2143 17.0234V12.452L12.9286 12.452ZM3.78573 6.73772V11.3092H6.07145V6.73772H3.78573ZM8.35716 1.02344V5.59487H10.6429V1.02344L8.35716 1.02344Z"
              stroke="#0059FF"
            />
          </svg>
        </Button>
        <Button
          variant="primary"
          id="filterBtnNavDesktop"
          aria-label="Buscar"
          value="Buscar"
          onClick={handleSearch}
        >
          <>BUSCAR </>
        </Button>
      </div>
      {showModalFilter && (
        <ModalFilter show={showModalFilter} close={() => setShowModalFilter(false)} />
      )}
    </>
  )
}
