import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IconTitle } from '../../../interfaces/enterprise/enterpriseIconTitle'
import { ITag } from '../../../interfaces/tag'

const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/tag` }),
  tagTypes: ['tags'],
  endpoints: (builder) => ({
    getAll: builder.mutation<DataRTKReturnList<ITag>, undefined>({
      query: () => ({
        url: `/get-all`,
        body: {
          description: null,
          activated: true
      },
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['tags']
    })
  })
})

export default tagsApi
