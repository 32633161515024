import React from 'react'
import { Suspense, useEffect, useState } from 'react'
import Logo from '../../../assets/glemo-logo.webp'
// import { X } from 'phosphor-react'

import { Accordion } from '../../common'
import ModalFilter from '../ModalFilter'
import { Link } from 'react-router-dom'

import Facebook from '../../../assets/icons/icon-gray-facebook.webp'
import Linkedin from '../../../assets/icons/icon-gray-linkedin.webp'
import Instagram from '../../../assets/icons/icon-gray-instagram.webp'
import Youtube from '../../../assets/icons/icon-gray-youtube.webp'
import { useAuth } from '../../../hooks/useAuth'
import { Button } from '../../common'
import SkeletonLoading from '../../common/SkeletonLoading'

interface Props {
  showModal: boolean
  closeModal: () => void
}

const listCidades = ['Belo Horizonte - MG', 'Belvedere, Belo Horizonte - MG', 'Belém PA']

const stylesAccordionTrigger = {
  fontSize: '20px',
  fontWeight: '600'
}

const stylesGlemoNasRedes = {}
export function ModalMenuMobile({ showModal, closeModal }: Props) {
  const [showPopup, setShowPopup] = useState(false)
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [valueInput, setValueInput] = useState('')
  const [listFilter, setListFilter] = useState(listCidades)
  const auth = useAuth()
  const [authenticated, setAuthenticated] = useState<boolean>()

  useEffect(() => {
    setAuthenticated(auth.authenticated)
  }, [auth])

  useEffect(() => {
    if (valueInput != undefined && valueInput.replace(' ', '') != '') {
      setListFilter([])
      const newList = listCidades.filter((element) => element.includes(valueInput))
      setListFilter(newList)
    } else {
      setListFilter(listCidades)
    }
  }, [valueInput])

  useEffect(() => {
    const closeModalMenuMobile = document.querySelector('#closeModalMenuMobile') as HTMLElement
    closeModalMenuMobile?.addEventListener('click', closeModal)
  }, [closeModal])

  return (
    <>
      {showModal ? (
        <>
          <div className="modal-form-busca modal-form-busca-mobile justify-center fixed z-[998] w-full h-full top-0">
            <div className="relative w-full h-full">
              <div className="container-modal justify-between pb-10 h-full border-0 mt-5 relative flex flex-col bg-white outline-none focus:outline-none p-4 overflow-y-auto w-full">
                <div>
                  <div className="flex justify-between">
                    <div className=" text-glemo-blue flex align-middle">
                      {/* <X size={25} className="text-glemo-blue" id='closeModalMenuMobile'></X> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        fill="currentColor"
                        className="text-glemo-blue"
                        id="closeModalMenuMobile"
                        viewBox="0 0 256 256"
                      >
                        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                      </svg>
                    </div>
                    <div className="m-auto">
                      <Suspense fallback={<SkeletonLoading />}>
                        <img

                          alt="Logo do Glemo"
                          src={Logo}
                          className="w-[130px] max-w-full"
                        />
                      </Suspense>
                    </div>
                    <div></div>
                  </div>
                  {authenticated ? (
                    <div className="mt-8">
                      <a href={'/perfil/dados-cadastrais'}>
                        <Button
                          id="myAccountMobile"
                          variant="orange"
                          className="text-[white] my-3 p-2 rounded font-bold text-center block w-[80%] min-w-auto m-auto"
                        >
                          MINHA CONTA
                        </Button>
                      </a>
                    </div>
                  ) : (
                    <div className="mt-8">
                      <a href={'/entrar'}>
                        <Button
                          id="loginMobile"
                          variant="primary"
                          className="text-[white] my-3 p-2 rounded font-bold text-center block w-[80%] min-w-auto m-auto"
                        >
                          LOGIN
                        </Button>
                      </a>
                    </div>
                  )}
                  {!authenticated && (
                    <div className="text-center">
                      <h5 className="text-lg">
                        <span className="text-[#666666]">Novo no glemO?</span>{' '}
                        <a href={'/registrar'} className="inline-block font-bold text-glemo-blue">
                          Cadastre-se
                        </a>
                      </h5>
                    </div>
                  )}
                  <div className={`${!authenticated && 'mt-8'}`}>
                    <Button
                      variant="transparent"
                      id="headerNavVisualizados"
                      style={{ justifyContent: 'start' }}
                      onClick={() => (window.location.href = '/visualizados')}
                    >
                      <div className="text-gray-700 px-0 py-0 hover:bg-gray-100 flex justify-start items-center gap-2.5 rounded transition-colors ease-in-out duration-250">
                        <span className="bg-gray-100 text-blue-700 rounded px-4 py-2">
                          {/* <ClockCounterClockwise size={20} /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            width="20"
                            viewBox="0 0 256 256"
                          >
                            <path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path>
                          </svg>
                        </span>
                        <span className="font-semibold tracking-wider pr-4 md:hidden lg:inline-block">
                          Visualizados
                        </span>
                      </div>
                    </Button>

                    <Button
                      variant="transparent"
                      id="headerNavFavoritos"
                      onClick={() => (window.location.href = '/favoritos')}
                      style={{ justifyContent: 'start' }}
                    >
                      <div className="text-gray-700 px-0 py-0 hover:bg-gray-100 flex justify-start items-center gap-2.5 rounded transition-colors ease-in-out duration-250">
                        <span className="bg-gray-100 text-blue-700 rounded px-4 py-2">
                          {/* <Heart size={20} /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            fill="currentColor"
                            viewBox="0 0 256 256"
                          >
                            <path d="M178,32c-20.65,0-38.73,8.88-50,23.89C116.73,40.88,98.65,32,78,32A62.07,62.07,0,0,0,16,94c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,220.66,240,164,240,94A62.07,62.07,0,0,0,178,32ZM128,206.8C109.74,196.16,32,147.69,32,94A46.06,46.06,0,0,1,78,48c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,147.61,146.24,196.15,128,206.8Z"></path>
                          </svg>
                        </span>
                        <span className="font-semibold tracking-wider pr-4 md:hidden lg:inline-block">
                          Favoritos
                        </span>
                      </div>
                    </Button>
                  </div>

                  <div>
                    <div>
                      <Accordion.Root
                        collapsible
                        type="single"
                        className="shadow-none bg-white p-0 m-0"
                      >
                        <Accordion.Item value="item-1 bg-white p-0 m-0">
                          <Accordion.Trigger className="text-black p-0 m-0 shadow-none opacity-70 text-xl font-semibold">
                            Para Empresas
                          </Accordion.Trigger>
                          <Accordion.Content className="">
                            <div>
                              <ul className="text-lg pl-4 py-2 font-semibold bg-transparent text-black opacity-70">
                                <li>
                                  <a href={'/parceiros'}>Parceiros</a>
                                </li>
                                <li>
                                  <a href="/construtoras-e-incorporadoras">
                                    Construtoras e Incorporadoras
                                  </a>
                                </li>
                                <li>
                                  <a href="/corretores">Corretores</a>
                                </li>
                                <li>
                                  <a href="/imobiliarias">Imobiliárias</a>
                                </li>
                              </ul>
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                    <div>
                      <Accordion.Root
                        collapsible
                        type="single"
                        className="shadow-none bg-white p-0 m-0"
                      >
                        <Accordion.Item value="item-1 bg-white p-0 m-0">
                          <Accordion.Trigger className="text-black p-0 m-0 shadow-none opacity-70 text-xl font-semibold">
                            Para você
                          </Accordion.Trigger>
                          <Accordion.Content className="">
                            <div>
                              <ul className="text-lg pl-4 py-2 font-semibold bg-transparent text-black opacity-70">
                                <li>
                                  <a href="/contato">Contato</a>
                                </li>
                              </ul>
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                    <div>
                      <Accordion.Root
                        collapsible
                        type="single"
                        className="shadow-none bg-white p-0 m-0"
                      >
                        <Accordion.Item value="item-1 bg-white p-0 m-0">
                          <Accordion.Trigger className="text-black p-0 m-0 shadow-none opacity-70 text-xl font-semibold">
                            Sobre o glemO
                          </Accordion.Trigger>
                          <Accordion.Content className="">
                            <div>
                              <ul className="text-lg pl-4 py-2 font-semibold bg-transparent text-black opacity-70">
                                <li>
                                  <a href="/o-glemo">O que é o glemO</a>
                                </li>
                                <li>
                                  <a href="/duvidas-frequentes">Dúvidas frequentes</a>
                                </li>
                                {/* <li>
                                <a href="/sala-de-imprensa">Sala de imprensa</a>
                              </li> */}
                                <li>
                                  <a href="https://deportasabertas.glemo.com.br/">Blog</a>
                                </li>
                              </ul>
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                    <div>
                      <Accordion.Root
                        collapsible
                        type="single"
                        className="shadow-none bg-white p-0 m-0"
                      >
                        <Accordion.Item value="item-1 bg-white p-0 m-0">
                          <Accordion.Trigger className="text-black p-0 m-0 shadow-none opacity-70 text-xl font-semibold">
                            Simuladores
                          </Accordion.Trigger>
                          <Accordion.Content className="">
                            <div>
                              <ul className="text-lg pl-4 py-2 font-semibold bg-transparent text-black opacity-70">
                                {/* <li>
                                  <a href="/simuladores">Simular</a>
                                </li> */}
                                <li>
                                  <a href="/simuladores/imovel-ideal">Imóvel Ideal</a>
                                </li>
                              </ul>
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                  </div>
                </div>
                <div className=" w-full ml-auto right-1">
                  <h6 className="text-center text-black opacity-70 font-bold text-2xl mt-10">
                    glemO nas redes
                  </h6>
                  <div className="grid grid-cols-4 mt-0 pt-5 gap-8 w-[max-content] m-auto">
                    <div className="m-auto">
                      <Suspense fallback={<SkeletonLoading />}>
                        <img

                          alt="Logo do Facebook"
                          src={Facebook}
                          className="m-auto"
                        />
                      </Suspense>
                    </div>
                    <div className="m-auto">
                      <Suspense fallback={<SkeletonLoading />}>
                        <img

                          alt="Logo do Linkedin"
                          src={Linkedin}
                          className="m-auto"
                        />
                      </Suspense>
                    </div>
                    <div className="m-auto">
                      <Suspense fallback={<SkeletonLoading />}>
                        <img

                          alt="Logo do Instagram"
                          src={Instagram}
                          className="m-auto"
                        />
                      </Suspense>
                    </div>
                    <div className="m-auto">
                      <Suspense fallback={<SkeletonLoading />}>
                        <img

                          alt="Logo do Youtube"
                          src={Youtube}
                          className="m-auto"
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <>
        <ModalFilter show={showModalFilter} blackBg close={() => setShowModalFilter(false)} />
      </>
    </>
  )
}
