import React from 'react'
import Icon from '../../assets/icons/icon-differentials-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import SkeletonLoading from '../common/SkeletonLoading'
import { v4 } from 'uuid'
import { isValidStr, strToSlug } from '../../common_functions'
import { IconTitle } from '../../interfaces/enterprise/enterpriseIconTitle'
import BannerNotFound from '../../assets/Image-not-found.webp'
import { IconName } from '../../interfaces/enterprise/enterpriseIconName'

interface EnterpriseDetailCard {
  title: string
  IconsTextList: IconTitle[] | null | undefined
  idHTML: string
  isOrangeSelect?: boolean
  hrMargin?: boolean
  select?: boolean
  changeSelectedItem?: (item: IconTitle) => void
}

export default function IconsText({
  title,
  IconsTextList,
  isOrangeSelect = false,
  idHTML,
  hrMargin = false,
  select = false,
  changeSelectedItem
}: EnterpriseDetailCard) {
  const [selectedItems, setSelectedItems] = useState<IconTitle[]>([])
  const [filterItems, setFilterItems] = useState<IconTitle[] | null | undefined>(IconsTextList?.slice(0, 10))
  const [isFullItems, setIsFullItems] = useState(false)

  const handleChangeSelectedItem = (item: IconTitle) => {
    if (selectedItems?.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x != item))
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
    if (changeSelectedItem != undefined) changeSelectedItem(item)
  }

  const handleChangeFilterItems = () => {
    if(isFullItems){
      setFilterItems(IconsTextList?.slice(0,10))
    }else{
      setFilterItems(IconsTextList)
    }
    setIsFullItems(!isFullItems)
  }

  useEffect(() => {
    // Atualize o estado filterItems quando IconsTextList mudar
    setFilterItems(IconsTextList?.slice(0, 10));
    setIsFullItems(false);
  }, [IconsTextList]);

  return (
    <>
      {IconsTextList != undefined && IconsTextList.length > 0 ? (
        <>
          <section className="p-3 pl-0" id={idHTML}>
            {isValidStr(title) && (
              <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">{title}</h3>
            )}
            {/* CONTENT */}
            <div className={clsx('flex gap-1 flex-wrap')}>
              <>
                {filterItems?.map((dif) => {
                  return (
                    <button disabled={!select} onClick={() => handleChangeSelectedItem(dif)}>
                      <div
                        key={`${strToSlug(dif.Title ?? v4())}`}
                        className={
                          'bg-[#F4F6F9] rounded-[14px] p-2 ' +
                          (select == true && selectedItems.includes(dif)
                            ? (isOrangeSelect ? 'border border-[var(--glemo-orange)] ' : 'border border-[var(--glemo-blue)] ')
                            : '')
                        }
                      >
                        <div className="grid grid-cols-auto w-max">
                          <div className="col-span-1 min-w-[32px]">
                            <Suspense fallback={<SkeletonLoading />}>
                              <img
                                alt="Icone de Diferenciais"
                                src={dif.Icon}
                                className="w-8 h-8 m-auto"
                              />
                            </Suspense>
                          </div>
                          <div className="text-start m-auto col-span-3 ms-3 col-start-2">
                            <h5
                              className={`text-caption lg:text-xl  ${
                                select && selectedItems.includes(dif)
                                  ? (isOrangeSelect ? 'text-[var(--glemo-orange)] font-bold' : 'text-[var(--glemo-blue)] font-bold')
                                  : 'text-black font-[500]'
                              }`}
                            >
                              {dif.Title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </button>
                  )
                })}
                {select && IconsTextList.length > 10 && <button className={`${isOrangeSelect ? 'text-[var(--glemo-orange)]' : 'text-[var(--glemo-blue)]'} text-lg font-bold ms-5`} onClick={handleChangeFilterItems}>Ver {isFullItems ? "menos" : "mais"}</button>}
              </>
            </div>
          </section>
          {hrMargin && <hr className={`mt-4 z-[-1] relative opacity-[0.1]`} />}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
