import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SearchLocal } from '../../classes/searchLocal'

interface IInitialState {
  items: string[]
}

const initialState:IInitialState = {
  items: []
}

const searchListLocalSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setItemsListLocal: (state,action: PayloadAction<string[]>) => {
      state.items = action.payload
    }
  }
})

export const {setItemsListLocal} = searchListLocalSlice.actions
export default searchListLocalSlice.reducer
