import { createSlice } from "@reduxjs/toolkit"
import { IContentProps } from "./interfaces/IContentProps"

const initialState: IContentProps = {}

const cardsEnterprisesIdsSlice = createSlice({
  name: "cardsEnterprisesIds",
  initialState,
  reducers: {}
})

export default cardsEnterprisesIdsSlice.reducer
