import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import { InputHTMLAttributes, ReactNode } from 'react'
import React from 'react'

export interface TextInputProps {
  className?: string
  children: ReactNode
  roundedInput?: boolean
}

export interface TextInputIconProps {
  className?: string
  children: ReactNode
}

export interface TextInputInputProps extends InputHTMLAttributes<HTMLInputElement> {
}

function TextInputRoot({ className, children, roundedInput = true }: TextInputProps) {
  return (
    <div
      className={clsx(
        `flex items-center gap-3 py-4 px-3 h-12 ${
          roundedInput ? 'rounded' : ''
        } border-1 border-gray-200 w-full focus-within:border-2 focus-within:border-blue-700 ${
          className?.includes('bg-') ? '' : 'bg-gray-100'
        }`,
        className
      )}
    >
      {children}
    </div>
  )
}

function TextInputIcon({ className, children }: TextInputIconProps) {
  return <Slot className={clsx(className, 'w-6 h-6 text-blue-700')}>{children}</Slot>
}
// function TextInputIcon({ children }: TextInputIconProps) {
//   return <Slot className="w-6 h-6 text-blue-700">{children}</Slot>
// }

function TextInputInput({ className, ...props }: TextInputInputProps) {
  return (
    <input
      {...props}
      className={clsx(
        className,
        `${className?.includes('bg-') ? '' : 'bg-gray-100'} flex-1 ${
          className?.includes('text-[') ? '' : 'text-md'
        } outline-none placeholder:text-gray-600 max-w-100`
      )}
    />
  )
}

TextInputRoot.displayName = 'TextInput.Root'
TextInputInput.displayName = 'TextInput.Input'
TextInputIcon.displayName = 'TextInput.Icon'

export const TextInput = {
  Root: TextInputRoot,
  Input: TextInputInput,
  Icon: TextInputIcon
}
