import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { News } from '../../../interfaces/pressroom/news'
import { Simulator, SimulatorFormAnswer } from '../../../interfaces/simulator/simulators'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { SimulatorAnswers, SimulatorEnterprise, SimulatorResponse } from '../../../interfaces/simulator/simulatorResponse'
import { EnterpriseDetail } from '../../../interfaces/enterprise/entepriseDetail'

const simulatorApi = createApi({
  reducerPath: 'simulatorApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/simulator` }),
  tagTypes: ['simulator'],
  endpoints: (builder) => ({
    getSimulationByDataUser: builder.mutation<DataRTKReturn<SimulatorResponse>, Simulator>({
      query: (content) => ({
        url: `/calculate`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['simulator']
    }),
    getAnswers: builder.query<DataRTKReturn<SimulatorAnswers>, undefined>({
      query: () => ({
        url: `/get-answers`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    sendSimulatorAnswers: builder.mutation<DataRTKReturnList<SimulatorEnterprise>, SimulatorFormAnswer>({
      query: (content) => ({
        url: `/send-answers`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['simulator']
    }),
  })
})

export default simulatorApi
