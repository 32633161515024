import { createSlice } from "@reduxjs/toolkit"
import { IDistrictProps } from "./interfaces/IDistrictStore"

const initialState: IDistrictProps = {}

const districtFetchSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {}
})

export default districtFetchSlice.reducer
