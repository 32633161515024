import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { IResponseError, IResponseErrorNotFound } from '../interfaces/IResponseApi'
import { News, NewsById } from '../../../interfaces/pressroom/news'
import { IRequestNewsGetAll } from '../../../interfaces/pressroom/api/requests'
import { NewsCategory } from '../../../interfaces/pressroom/newsCategory'
import { GalleryFile } from '../../../interfaces/pressroom/galleryFile'
import { FullGalleryFile } from '../../../interfaces/pressroom/fullGalleryFile'

const pressroomApi = createApi({
  reducerPath: 'pressroomApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['pressroom'],
  endpoints: (builder) => ({
    getNewsAll: builder.mutation<DataRTKReturnList<News>, null>({
      query: () => ({
        url: `/news/get-all`,
        body: JSON.stringify({
          title: 'string',
          text: 'string',
          categoryId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['pressroom']
    }),
    getNewsByCategoryId: builder.mutation<DataRTKReturnList<News>, string>({
      query: (categoryId) => ({
        url: `/news/get-by-category`,
        body: JSON.stringify({
          title: 'string',
          text: 'string',
          categoryId: categoryId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['pressroom']
    }),
    getNewsById: builder.mutation<DataRTKReturnList<NewsById>, string>({
      query: (id) => ({
        url: `/news/get-news-by-id`,
        body: JSON.stringify({
          id: id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['pressroom']
    }),
    getNewsCategoryAll: builder.mutation<DataRTKReturnList<NewsCategory>, null>({
      query: () => ({
        url: `/news-category/get-all`,
        body: JSON.stringify({
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          name: 'string'
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['pressroom']
    }),
    getAllGalleryFiles: builder.query<DataRTKReturnList<GalleryFile>, null>({
      query: () => ({
        url: `/gallery-files/get-file-list`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getGalleryFileById: builder.query<DataRTKReturn<FullGalleryFile>, string>({
      query: (id) => ({
        url: `/gallery-files/get-file-by-id/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
})

export default pressroomApi
