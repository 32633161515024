import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import React from 'react'
// import { Check } from 'phosphor-react'
import { forwardRef, Ref } from 'react'

export interface RadioGroupProps extends RadixRadioGroup.RadioGroupProps {
  direction: 'row' | 'col'
}

interface RadioGroupItemProps extends RadixRadioGroup.RadioGroupItemProps {
  size?: 'base' | 'large'
  variant?: 'primary' | 'orange'
  check?: boolean
}

const RadioGroupRoot = forwardRef(
  (
    { children, className, direction, ...props }: RadioGroupProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    return (
      <RadixRadioGroup.Root
        ref={forwardedRef}
        {...props}
        className={clsx(className, `flex flex-${direction} gap-2.5`)}
      >
        {children}
      </RadixRadioGroup.Root>
    )
  }
)

const RadioGroupItem = forwardRef(
  (
    {
      children,
      className,
      variant = 'primary',
      size = 'base',
      check = false,
      ...props
    }: RadioGroupItemProps,
    forwardedRef: Ref<HTMLButtonElement>
  ) => {
    return (
      <RadixRadioGroup.Item
        ref={forwardedRef}
        {...props}
        className={clsx(
          className,
          { 'w-10.5 h-10.5': size === 'large' },
          { 'rounded-full': check === false },
          { 'rounded-sm': check === true },
          // 'h-6 w-6 flex items-center justify-center rounded-full border-1 font-semibold border-gray-200 text-gray-600  state-checked:text-white state-checked:border-0',
          'h-6 w-6 flex items-center justify-center border-1 font-semibold border-gray-200 text-gray-600  state-checked:text-white state-checked:border-0',
          { 'state-checked:bg-blue-700': variant === 'primary' },
          { 'state-checked:bg-[#F15C00]': variant === 'orange' }
        )}
      >
        {check === true ? (
          // <Check weight="bold" size={12} color="#ffffff" />
          <svg xmlns="http://www.w3.org/2000/svg" width="12" fill="#ffffff" viewBox="0 0 256 256">
            <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
          </svg>
        ) : (
          <>{children}</>
        )}
      </RadixRadioGroup.Item>
    )
  }
)

const RadioGroupIndicator = forwardRef(
  (
    { children, className, ...props }: RadixRadioGroup.RadioGroupIndicatorProps,
    forwardedRef: Ref<HTMLSpanElement>
  ) => {
    return (
      <RadixRadioGroup.Indicator
        ref={forwardedRef}
        {...props}
        className={clsx(className, { 'w-1/3 h-1/3 bg-white rounded-full': children === undefined })}
      >
        {children}
      </RadixRadioGroup.Indicator>
    )
  }
)

RadioGroupRoot.displayName = 'RadioGroup.Root'
RadioGroupItem.displayName = 'RadioGroup.Item'
RadioGroupIndicator.displayName = 'RadioGroup.Indicator'

export const RadioGroup = {
  Root: RadioGroupRoot,
  Item: RadioGroupItem,
  Indicator: RadioGroupIndicator
}
