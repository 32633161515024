export class ResponseLogin {
  AccessToken: string = ''
  ExpiresIn: string = ''
  RefreshToken: string = ''
  User: IUser | null = null

  constructor(accessToken: string, expiresIn: string, refreshToken: string, user: IUser | null) {
    this.AccessToken = accessToken
    this.ExpiresIn = expiresIn
    this.RefreshToken = refreshToken
    this.User = user
  }
}

export interface IUser {
  Id: string
  Email: string
  Claims: IClaim[]
  Roles: IRole[]
}

export interface IRole {
  Name: string
}

export interface IClaim {
  Type: string
  Value: string
}
