import { createSlice } from "@reduxjs/toolkit"
import { IGeneralProps } from "./interfaces/IGeneralStore"

const initialState: IGeneralProps = {}

const generalFetchSlice = createSlice({
  name: "generals",
  initialState,
  reducers: {}
})

export default generalFetchSlice.reducer
