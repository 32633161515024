export const HISTORICO_BUSCAS = 'historico-de-buscas'
export const LOCATION = 'location'
export const FILTER_ADDRESS = 'filter-address'
export const FILTER_NUMBER_BEDROOMS = 'filter-bedrooms'
export const FILTER_PRICE_MAX = 'filter-price-max'
export const FILTER_PRICE_MIN = 'filter-price-min'
export const FILTER_area_MAX = 'filter-area-max'
export const FILTER_DIFFERENTIALS = 'filter-differentials'
export const FILTER_area_MIN = 'filter-area-min'
export const FILTER_BATHROOMS = 'filter-bathrooms'
export const FILTER_PARKING_SPACES = 'filter-parking-spaces'
export const FILTER_SUIT = 'filter-suit'
export const FILTER_LIST_STATUS = 'filter-list-status'
export const FILTER_CITY = 'filter-city'
export const FILTER_STATE = 'filter-state'
export const FILTER_DISTRICT = 'filter-district'
export const CODE = 'code-web'
export const EMAIL = 'email'
export const FIRST_COOKIE = 'first-cookie'
export const LOCATIONS = 'locations'
export const VIEWED_ENTERPRISES = 'user-viewed-enterprises'
export const RECENTS_VIEWED_ENTERPRISES = 'user-recents-viewed-enterprises'
export const EMAIL_FORGOT_PASSWORD = 'email-forgot-password'
export const STATUS_FULL = 'status-full'
export const FILTER_SELECTED_STATUS = 'selected-status-filter'
export const RELOAD_PAGE = 'reload-page'
export const DATE_ENTRY = 'date-entry'
export const DEVICE_ID = 'device-id'
export const LOCALIDADES_TEMP = 'temp-locals'
export const STATES = 'states'
export const CITIES = 'states-cities'
export const SELECTED_STATE = 'selected-state'
export const SELECTED_CITY = 'selected-city'
export const SELECTED_DISTRICT = 'selected-district'
export const SELECTED_LOCATION = 'selected-location'
export const NAME_OF_EMPTY = 'manual-empty'
export const FORM_INFOS_CONTACT_LEAD = 'form-infos-contact-lead'
export const FULL_TEXT_FILTER = 'full-text-filter'
export const FULL_TEXT = "full-text"
export const DIFFERENTIALS_FILTER = 'differentials-modal-filter'
export const STATUS_FILTER = "status-modal-filter"
export const SIMULATION_DATA_BACKUP = "simulation-data-backup"
export const ARGUMENTS_AUTOCOMPLETE = 'arguments-autocomplete'
export const SEL_ARGUMENTS_AUTOCOMPLETE = 'selected-arguments-autocomplete'
export const ID_PRODUCT_SEARCHED_VIEWED = 'id-product-searched-viewed'
export const TYPE_LOGIN = 'type-login'
