import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ImgPrimary from '../../assets/ex-enterprise.webp'
import Logo from '../../assets/mrv-logo.webp'
import { NA_PLANTA, LANCAMENTO, PRONTO_PARA_MORAR, EM_OBRAS } from '../../constants/categoryEnterprise'

const initialState = [
  {
    id: 1,
    imgUrl: { ImgPrimary },
    category: NA_PLANTA,
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
  {
    id: 2,
    imgUrl: { ImgPrimary },
    category: LANCAMENTO,
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
  {
    id: 3,
    imgUrl: { ImgPrimary },
    category: PRONTO_PARA_MORAR,
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
  {
    id: 4,
    imgUrl: { ImgPrimary },
    category: 'LANÇAMENTO',
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
  {
    id: 5,
    imgUrl: { ImgPrimary },
    category: EM_OBRAS,
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
  {
    id: 6,
    imgUrl: { ImgPrimary },
    category: EM_OBRAS,
    title: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    logo: { Logo },
    advertiser: 'MRV',
    price: 500.0,
    route: '/empreendimento',
    favorite: false
  },
]

const enterprisesSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {
    changeFavorite: (state,action: PayloadAction<number>) => {
      state = state.map(item => {
        if(item.id === action.payload) item.favorite = !item.favorite
        return item
      })
    }
  }
})

export const {changeFavorite} = enterprisesSlice.actions
export default enterprisesSlice.reducer
