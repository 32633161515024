import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { State } from '../../../interfaces/state'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturnList } from '../../../interfaces/dataRTKReturn'

interface bodyCountryName{
  countryId: string,
  name: string
}

const stateApi = createApi({
  reducerPath: 'stateApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/states` }),
  tagTypes: ['states'],
  endpoints: (builder) => ({
    getStatesByCountryId: builder.mutation<DataRTKReturnList<State>, string>({
      query: (id_country) => ({
        url: `/get-all-by-country-id`,
        body: JSON.stringify({
          id: id_country
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['states']
    }),
    getStatesByCountrySlug: builder.mutation<DataRTKReturnList<State>, string>({
      query: (slug_country) => ({
        url: `/get-all-by-country-slug`,
        body: JSON.stringify({
          slug: slug_country
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['states']
    }),
    getStatesByCountryNameId: builder.mutation<DataRTKReturnList<State>, bodyCountryName>({
      query: (country_name_id) => ({
        url: `/get-all-by-country`,
        body: JSON.stringify(country_name_id),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['states']
    }),
    getStateIdByName: builder.mutation<DataRTKReturnList<State>, bodyCountryName>({
      query: (country_name_id) => ({
        url: `/get-all-by-country`,
        body: JSON.stringify(country_name_id),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['states']
    }),
  })
})

export default stateApi
