import Skeleton from 'react-loading-skeleton'
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

interface Props{
  count?: number
  height?: number|string
  className?: string
}

export default function SkeletonLoading({count,height='auto', className}:Props){
  return(
    <Skeleton count={count} height={height} className={className}/>
  )
}
