import { createSlice } from "@reduxjs/toolkit"
import { ISimulatorProps } from "./interfaces/ISimulatorStore"

const initialState: ISimulatorProps = {}

const simulatorSlice = createSlice({
  name: "simulator",
  initialState,
  reducers: {}
})

export default simulatorSlice.reducer
