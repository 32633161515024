import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { DataRTKReturn } from '../../../interfaces/dataRTKReturn'
import { IBuilder } from '../../../interfaces/builder/builder'

const builderApi = createApi({
  reducerPath: 'builderApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/construction-company` }),
  tagTypes: ['builders'],
  endpoints: (builder) => ({
    getDetails: builder.mutation<DataRTKReturn<IBuilder>, string>({
      query: (id) => ({
        url: `/detail`,
        body: JSON.stringify({
          "id": id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['builders']
    }),
  })
})

export default builderApi
