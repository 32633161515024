import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  LANCAMENTO,
  PRONTO_PARA_MORAR,
  NA_PLANTA,
  EM_OBRAS
} from '../../constants/categoryEnterprise'
import statusApi from '../../store/reducers/status/statusApi'
import SkeletonLoading from '../common/SkeletonLoading'

interface Props {
  name?: string
  id?: string
  className?: string
  isBorder?: boolean
  fontSize?: string
  hiddenNotCategory?: boolean
}

const listStates = [LANCAMENTO, PRONTO_PARA_MORAR, NA_PLANTA, EM_OBRAS]

export function CategoryEnterprise({ id, name, className, isBorder = false, hiddenNotCategory = false, fontSize = '20px' }: Props) {
  const [nameStatus, setNameStatus] = useState(name)

  return (
    <>
      {nameStatus && (
        <div
          style={{ fontSize: fontSize }}
          className={clsx(className + ' font-bold py-2 pl-3 pe-2 max-w-full text-start text-base', {
            'text-[#00A167] bg-[#E6F6F0]': nameStatus.toUpperCase() === LANCAMENTO,
            'text-[#9747FF] bg-[#F5EDFF]': nameStatus.toUpperCase() === NA_PLANTA,
            'text-[#F15C00] bg-[#FFEFD6]': nameStatus.toUpperCase() === EM_OBRAS,
            'text-[#0059FF] bg-[#E6F6FC]': nameStatus.toUpperCase() === PRONTO_PARA_MORAR,
            'bg-[#d9d9d9] ms-1': !listStates.includes(nameStatus.toUpperCase()),
            border: isBorder
          })}
        >
          {nameStatus.toUpperCase()}
        </div>
      )}
      {!nameStatus && !hiddenNotCategory && (
        <div
          style={{ fontSize: fontSize }}
          className={clsx(
            'rounded font-bold py-1 pl-3 w-full text-start text-base text-[gray] bg-[#e5e5e5]'
          )}
        >
          Sem categoria
        </div>
      )}
    </>
  )
}
