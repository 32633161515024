import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { EnterpriseDetail } from '../../../interfaces/enterprise/entepriseDetail'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IContentCard } from '../../../interfaces/enterprise/argsApi'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { IMail } from '../../../interfaces/mail'

const mailApi = createApi({
  reducerPath: 'mailApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/email` }),
  tagTypes: ['mails'],
  endpoints: (builder) => ({
    sendMail: builder.mutation<IDefaultResponse, IMail>({
      query: (content) => ({
        url: `/send-contact`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['mails']
    }),
  })
})

export default mailApi
