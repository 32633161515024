import React, { memo, useEffect, useState } from 'react'
import { Button, TextInput } from '../../common'
import BgBestProperties from '../../../assets/bg-best-properties.webp'
import ModalFilter from '../../Modais/ModalFilter'
import {
  FILTER_NUMBER_BEDROOMS,
  FILTER_PRICE_MAX,
  FILTER_PRICE_MIN,
  FULL_TEXT,
  HISTORICO_BUSCAS,
  LOCATIONS,
  SEL_ARGUMENTS_AUTOCOMPLETE
} from '../../../constants/storageNames'
import { Cookies } from 'react-cookie'
import { isValidList,  } from '../../../common_functions'
import search from '../../../store/reducers/search'
import SeparatorMicrophoneGray from '../../../assets/icons/separator-microphone-gray.svg'
import Voice2Text from '../../Voice2Text'
import { Argument } from '../../../interfaces/arguments'
import SearchAutocomplete from '../../SearchAutocomplete'

function SectionBestPropertiesFullText() {
  const [showModal, setShowModal] = useState(false)
  const [valueInput, setValueInput] = useState('')
  const [keyPress, setKeyPress] = useState<string>('')
  const [selectedArguments, setSelectedArguments] = useState<Argument[]>()
  const [focusOut, setFocusOut] = useState(false)
  const [isVoiceTxt, setIsVoiceTxt] = useState(false)

  const cookies = new Cookies()
  const [originalList, setOriginalList] = useState<string[]>([])

  const updateListOriginByCookies = () => {
    let locationsCookies = cookies.get(LOCATIONS)?.split(',') ?? ''
    setOriginalList(locationsCookies)
  }

  useEffect(() => {
    if (!isValidList(originalList)) {
      updateListOriginByCookies()
    }
  }, [location.pathname])

  useEffect(() => {
    let enderecoBuscaEmpreendimentoHTML = document.querySelector(
      '#enderecoBuscaEmpreendimento'
    ) as HTMLElement
    if (enderecoBuscaEmpreendimentoHTML != undefined && !isValidList(originalList))
      enderecoBuscaEmpreendimentoHTML.addEventListener('focus', updateListOriginByCookies)
  }, [])

  const updateSelectedArguments = () => {
    let updatedSelectedArguments = selectedArguments
    selectedArguments?.forEach((element) => {
      if (!valueInput.toLowerCase().includes(element.Title.toLowerCase())) {
        updatedSelectedArguments = updatedSelectedArguments?.filter((x) => x != element)
      }
    })
    sessionStorage.setItem(SEL_ARGUMENTS_AUTOCOMPLETE, JSON.stringify(updatedSelectedArguments))
  }

  const handleSearch = () => {
    const locals = sessionStorage.getItem(HISTORICO_BUSCAS)
    sessionStorage.setItem(HISTORICO_BUSCAS, `${locals}&${search}`)
    updateSelectedArguments()
    searchEnterprise()
  }

  const handleSearchKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setKeyPress(e.key)
    if (e.key == 'Enter') {
      handleSearch()
    }
  }

  const searchEnterprise = () => {
    let strBuscaURL = `?search=enterprises&fullText=${valueInput}`
    window.location.href = `/busca/${strBuscaURL}`
  }

  const handleChangeTranscript = (transcript: string) => {
    setValueInput(transcript.trim())
  }

  const handleChangeInput = (val: string) => {
    setValueInput(val)
    sessionStorage.setItem(FULL_TEXT, val)
  }

  return (
    <section className="section-best-properties aspect-[1/.8] md:aspect-auto flex">
      <div
        style={{ backgroundImage: `url(${BgBestProperties})` }}
        className="w-full h-auto md:h-[528px] bg-cover bg-no-repeat bg-center rounded-lg md:rounded-none"
      >
        <div
          id="containerSearchProperty"
          data-padding={40}
          className={
            'container-search-property max-w-[1000px] rounded-lg mx-5 lg:mx-auto md:w-4/5 m-auto text-center py-6 px-10 shadow-[0px_4px_24px_rgba(0,0,0,0.12)] relative -bottom-[16rem] left-0 right-0'
          }
          style={{ backgroundColor: `rgba(255,255,255,0.9)` }}
        >
          <hr className="h-[4px] w-[180px] max-w-full bg-[#F15C00] text-transparent" />
          <h2 className="text-[22px] lg:text-5xl text-black mt-5">
            Busque seu <span className="font-semibold">novo imóvel</span>{' '}
            <span className="font-bold text-[#0059FF]">no glemO</span>
          </h2>
          <div className="w-full mt-8">
            <div className="grid grid-cols-7 gap-2">
              <div className="col-span-5">
                <div className="flex px-2 rounded text-md border-1 border-gray-200 outline-none placeholder:text-gray-600 max-w-100 bg-[#C4C4C433]">
                  <TextInput.Root className="border-none bg-transparent">
                    <TextInput.Input
                      onChange={(e) => handleChangeInput(e.target.value)}
                      value={valueInput}
                      onBlur={() => setFocusOut(true)}
                      onFocus={() => setFocusOut(false)}
                      onKeyUp={(e) => handleSearchKey(e)}
                      className={`w-full bg-transparent input-search-best-properties-full-text`}
                      placeholder={'Digite qualquer palavra, incluindo bairro cidade ou região'}
                    />
                  </TextInput.Root>
                  <div className="text-center m-auto pe-2">
                    <img src={SeparatorMicrophoneGray} alt="Separador" />
                  </div>
                  <div className="text-center m-auto cursor-pointer">
                    {/* <img src={MicrophoneIcon} /> */}
                    <Voice2Text
                      changeTranscript={(transcript: string) => handleChangeTranscript(transcript)}
                    />
                  </div>
                </div>
                <SearchAutocomplete
                  onFocusOutInput={focusOut}
                  changedInputValue={valueInput}
                  onInputKeydown={keyPress}
                  classNameSearchHTML='input-search-best-properties-full-text'
                  handleSearch={handleSearch}
                  isVoiceTxt={isVoiceTxt}
                  changeInputValue={(val) => setValueInput(val)}
                  changeSelectedArguments={(args) => setSelectedArguments(args)}
                />
              </div>
              <div className="col-span-2 m-auto text-center max-w-100">
                <Button
                  id="searchBestProperties"
                  variant="primary"
                  onClick={handleSearch}
                  className="font-bold text-xl w-[200px] max-w-100"
                >
                  BUSCAR
                </Button>
              </div>
            </div>
          </div>
          <ModalFilter
            show={showModal}
            close={() => setShowModal(false)}
            elementInputCityId="inputSearchEnterprisesSectionBestProperties"
          />
        </div>
      </div>
    </section>
  )
}
export default memo(SectionBestPropertiesFullText)
