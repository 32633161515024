import React, { useCallback, useLayoutEffect } from 'react'
import { CategoryEnterprise } from '../../CategoryEnterprise'
import { Button, RadioGroup, TextInput } from '../../common'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import {
  FILTER_ADDRESS,
  FILTER_NUMBER_BEDROOMS,
  FILTER_PRICE_MAX,
  FILTER_PRICE_MIN,
  FILTER_SELECTED_STATUS,
  HISTORICO_BUSCAS,
  SELECTED_CITY,
  SELECTED_DISTRICT,
  SELECTED_STATE,
  STATUS_FULL
} from '../../../constants/storageNames'
import { useEffect, useState } from 'react'
import { isValidList, isValidStr, strToSlug } from '../../../common_functions'
import statusApi from '../../../store/reducers/status/statusApi'
import { Status } from '../../../interfaces/status'
import Loading from '../../Loading'
import { convertString } from '../../../common_functions'
import { idCityHTML, idDistrictHTML, idStateHTML } from '../../../constants/idClassHTML'

interface Props {
  show: boolean
  blackBg?: boolean
  close: () => void
  isInternSearch?: boolean
  searchFunct?: () => void
  elementInputCityId?: string
  isBackup?: boolean
}

export default function ModalFilter({
  show,
  close,
  blackBg = false,
  isInternSearch = false,
  searchFunct,
  elementInputCityId,
  isBackup = false
}: Props) {
  const bedRoomsList = [1, 2, 3, 4]
  const search = useSelector((state: RootState) => state.search)
  const minValorImovelLocal = localStorage.getItem(FILTER_PRICE_MIN) ?? ''
  const maxValorImovelLocal = localStorage.getItem(FILTER_PRICE_MAX) ?? ''
  const quartosLocal = localStorage.getItem(FILTER_NUMBER_BEDROOMS) ?? undefined
  const categoriaSelectLocal = localStorage.getItem(FILTER_SELECTED_STATUS) ?? ''
  const [useStatus, { data, isError, isLoading, isSuccess }] = statusApi.useGetStatusAllMutation()
  const categoriasLocal: Status[] = JSON.parse(localStorage.getItem(STATUS_FULL) ?? '[]')

  const [categoriaSelect, setCategoriaSelect] = useState(isBackup ? categoriaSelectLocal : '')
  const [categorias, setCategorias] = useState<Status[] | undefined>(categoriasLocal)
  const [minValorImovel, setminValorImovel] = useState<string>(isBackup ? minValorImovelLocal : '')
  const [maxValorImovel, setmaxValorImovel] = useState<string>(isBackup ? maxValorImovelLocal : '')
  const [quartos, setQuartos] = useState<number | undefined>(
    quartosLocal != undefined && isBackup ? parseInt(quartosLocal) : undefined
  )

  useLayoutEffect(() => {
    if (!isValidList(categorias)) getStatus()
  }, [])

  const getStatus = async () => {
    await useStatus(undefined)
  }

  useEffect(() => {
    if (isSuccess && data != undefined) {
      setCategorias(data?.Data)
      localStorage.setItem(
        STATUS_FULL,
        convertString<Status>(data?.Data).replaceAll('}', '},').replaceAll('},]', '}]')
      )
    }
  }, [data?.Data])

  const handleSearch = () => {
    const locals = sessionStorage.getItem(HISTORICO_BUSCAS)
    updateSearchInputLocals()
    sessionStorage.setItem(HISTORICO_BUSCAS, `${locals}&${search}`)
    saveLocalFilter()

    if (isInternSearch && searchFunct != undefined) searchFunct()
    else searchEnterprise()
    close()
  }

  const updateSearchInputLocals = () => {
    //UPDATE STATE
    let selectStateHTML = document.querySelector(`#${idStateHTML}`) as HTMLSelectElement
    if(selectStateHTML != undefined && selectStateHTML.value.toUpperCase() == 'ESTADO')
      sessionStorage.removeItem(SELECTED_STATE)

    //UPDATE CITY
    let inputCityHTML = document.querySelector(`#${idCityHTML}`) as HTMLInputElement
    if(inputCityHTML != undefined && !isValidStr(inputCityHTML.value))
      sessionStorage.removeItem(SELECTED_CITY)

    //UPDATE DISTRICT
    let inputDistrictHTML = document.querySelector(`#${idDistrictHTML}`) as HTMLInputElement
    if(inputDistrictHTML != undefined && !isValidStr(inputDistrictHTML.value))
      sessionStorage.removeItem(SELECTED_DISTRICT)
  }

  const saveLocalFilter = () => {
    if(elementInputCityId != undefined){
      let cityHTML = document.querySelector(`#${elementInputCityId}`) as HTMLInputElement
      if(cityHTML != undefined){
        let val = cityHTML.value
        if(!isValidStr(val)) localStorage.setItem(FILTER_ADDRESS, "")
      }
    }
    localStorage.setItem(FILTER_SELECTED_STATUS, categoriaSelect)
    localStorage.setItem(FILTER_NUMBER_BEDROOMS, quartos?.toString() ?? '')
    localStorage.setItem(FILTER_PRICE_MAX, maxValorImovel?.toString() ?? '')
    localStorage.setItem(FILTER_PRICE_MIN, minValorImovel?.toString() ?? '')
  }

  const searchEnterprise = () => {
    let strBuscaURL = '?search=filter'
    if (isValidStr(minValorImovel)) {
      strBuscaURL += `&priceMinimumValue=${minValorImovel}`
    }
    if (isValidStr(maxValorImovel)) {
      strBuscaURL += `&priceMaximumValue=${minValorImovel}`
    }
    if (quartos != undefined) {
      strBuscaURL += `&numberOfRooms=${quartos}`
    }
    window.location.href = `/busca/${strBuscaURL}`
  }

  const handleSelectedCategoria = useCallback((add: boolean, item: Status, indexItem: number) => {
    localStorage.setItem(FILTER_SELECTED_STATUS, `${item.Name}/${item.Id}`)
    setCategoriaSelect(`${item.Name}/${item.Id}`)
    handleUpdateCategorias()
  }, [categoriaSelect])

  const isCheck = (idItem: string) => {
    const checkHTML = document.querySelector(`#${idItem}`) as HTMLElement
    let check = 'false'
    if (checkHTML != undefined) {
      check = checkHTML.getAttribute('aria-checked') ?? 'false'
    }
    return check == 'true'
  }

  const handleUpdateCategorias = useCallback(() => {
    let newCategorias = categoriasLocal
    newCategorias?.forEach((element) => {
      element.IsActive = categoriaSelect.includes(element.Name)
    })
    setCategorias(newCategorias)
  }, [categorias])

  const handleSetMinPrice = useCallback((val:string) => {
    localStorage.setItem(FILTER_PRICE_MIN, val)
    setminValorImovel(val)
  }, [minValorImovel])

  const handleSetMaxPrice = useCallback((val:string) => {
    localStorage.setItem(FILTER_PRICE_MAX, maxValorImovel?.toString() ?? '')
    setmaxValorImovel(val)
  }, [maxValorImovel])

  const handleSetSelectedQuarto = useCallback((quarto:number) => {
    localStorage.setItem(FILTER_NUMBER_BEDROOMS, quartos?.toString() ?? '')
    setQuartos(quarto)
  }, [maxValorImovel])

  return (
    <>
      {show ? (
        <div className={clsx({ 'fixed inset-0 z-[999] bg-[#00000000]': blackBg })}>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none w-[316px] max-w-full m-auto">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-2xl font-semibold">Status do imóvel</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none"
                    id="btnCloseModalFilter"
                    onClick={close}
                  >
                    <span className="bg-transparent h-6 w-6 outline-none focus:outline-none">
                      <svg
                        width={16}
                        fill="black"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                      >
                        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                      </svg>
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {isLoading && <div id="loadingBox"></div>}
                  {isError && (
                    <div
                      style={{ backgroundColor: 'rgba(255, 237, 213, 0.2)' }}
                      className="mt-4 border-l-4 border-orange-500 text-orange-700 p-4"
                      role="alert"
                    >
                      <p className="font-bold">Houve um Erro!</p>
                      <p>
                        Erro ao carregar os status do filtro. Tente atualizar a página novamente.
                      </p>
                    </div>
                  )}
                  {isSuccess ||
                    (isValidList(categorias) && (
                      <>
                        <div>
                          <RadioGroup.Root direction="col">
                            {categorias?.map((item, index) => {
                              return (
                                <>
                                  <div className="grid grid-cols-5">
                                    <div className="col-span-1">
                                      <RadioGroup.Item
                                        size="base"
                                        // className="w-[20px] h-[20px]"
                                        value={strToSlug(item.Name)}
                                        onClick={() =>
                                          handleSelectedCategoria(
                                            isCheck(`categoria${strToSlug(item.Name)}`),
                                            item,
                                            index
                                          )
                                        }
                                        checked={categoriaSelect.includes(item.Name)}
                                        id={`categoria${strToSlug(item.Name)}`}
                                      >
                                        <RadioGroup.Indicator forceMount>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            fill="#ffffff"
                                            viewBox="0 0 256 256"
                                          >
                                            <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                                          </svg>
                                        </RadioGroup.Indicator>
                                      </RadioGroup.Item>
                                    </div>
                                    <div className="col-span-4">
                                      <CategoryEnterprise name={item.Name} className="w-full" />
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </RadioGroup.Root>
                        </div>
                      </>
                    ))}
                  <hr className="mt-5 bg-gray-500 text-gray-500" />
                  <div className="text-left mt-5">
                    <h3 className="text-2xl font-semibold">Valor do imóvel</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                      <div>
                        <TextInput.Root>
                          <TextInput.Input
                            onChange={(e) => handleSetMinPrice(e.target.value)}
                            placeholder="Min."
                            value={minValorImovel}
                            className="text-black font-semibold placeholder:text-black placeholder:font-semibold text-lg w-full"
                          />
                        </TextInput.Root>
                      </div>
                      <div>
                        <TextInput.Root>
                          <TextInput.Input
                            placeholder="Max."
                            value={maxValorImovel}
                            onChange={(e) => handleSetMaxPrice(e.target.value)}
                            className="text-black font-semibold placeholder:text-black placeholder:font-semibold text-lg w-full"
                          />
                        </TextInput.Root>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-5 bg-gray-500 text-gray-500" />
                  <div className="text-start mt-3">
                    <h3 className="text-2xl font-semibold">Quartos</h3>
                    <div className="mt-2">
                      <RadioGroup.Root direction="row">
                        {bedRoomsList?.map((item, index) => {
                          return (
                            <RadioGroup.Item
                              size="large"
                              className="w-[42px] h-[42px]"
                              value={item.toString()}
                              checked={quartos == item}
                              onClick={() => handleSetSelectedQuarto(item)}
                            >
                              <RadioGroup.Indicator forceMount>
                                <span className="text-xl" style={{ verticalAlign: 'top' }}>
                                  {item}
                                  {index === bedRoomsList.length - 1 && '+'}
                                </span>
                              </RadioGroup.Indicator>
                            </RadioGroup.Item>
                          )
                        })}
                      </RadioGroup.Root>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-6 rounded-b">
                  <Button
                    variant="primary"
                    id="btnSearchModalFilter"
                    onClick={handleSearch}
                    className="ml-3 font-bold w-1/2 max-w-full"
                  >
                    {/* <MagnifyingGlass size={16}/>  */}
                    <svg
                      width={32}
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                    </svg>
                    BUSCAR
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[100] bg-black"></div>
        </div>
      ) : null}
    </>
  )
}
