import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { DataRTKReturn } from '../../../interfaces/dataRTKReturn'
import { Argument } from '../../../interfaces/arguments'
import { IProductSearchedRequest } from './interfaces/IProductSearchedRequest'

const autocompleteApi = createApi({
  reducerPath: 'autocompleteApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['autocompletes'],
  endpoints: (autocomplete) => ({
    getArguments: autocomplete.query<Argument[], undefined>({
      query: () => `/helpers/auto-complete/argument-address-full-text`
    }),
    productSearched: autocomplete.mutation<
      boolean,
      IProductSearchedRequest
    >({
      query: (content) => ({
        url: `/product-searched/add-or-update`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
  })
})

export default autocompleteApi
