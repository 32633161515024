export const USER_CLAIM_ROLE = 'user-claim-role'
export const USER_CLAIM_FULLNAME = 'user-claim-fullname'
export const USER_CLAIM_EMAIL = 'user-claim-email'
export const USER_CLAIM_ID = 'user-claim-id'
export const USER_CLAIM_NAME = 'user-claim-name'
export const USER_CLAIM_PHONENUMBER = 'user-claim-phonenumber'
export const USER_CLAIM_FAVORITES_ENTERPRISES = 'user-claim-favorites-enterprises'


export const USER_CLAIM_TYPE_NAME_ROLE = 'user:roles'
export const USER_CLAIM_TYPE_NAME_FULLNAME = 'user:fullname'
export const USER_CLAIM_TYPE_NAME_EMAIL = 'user:email'
export const USER_CLAIM_TYPE_NAME_ID = 'user:id'
export const USER_CLAIM_TYPE_NAME_NAME = 'user:name'
export const USER_CLAIM_TYPE_NAME_PHONENUMBER = 'user:phonenumber'
export const USER_CLAIM_TYPE_NAME_FAVORITES_ENTERPRISES = 'user:favoritesenterprise'
