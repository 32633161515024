import { createSlice } from "@reduxjs/toolkit"
import { State } from "../../../interfaces/state"
import { ILeadProps } from "./interfaces/ILeadStore"
const initialState: ILeadProps = {}

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {}
})

export default leadsSlice.reducer
