import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { District } from '../../../interfaces/district'
import { DataRTKReturnList } from '../../../interfaces/dataRTKReturn'

const districtApi = createApi({
  reducerPath: 'districtApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/districts` }),
  tagTypes: ['districts'],
  endpoints: (builder) => ({
    getAllByCity: builder.mutation<DataRTKReturnList<District>, number>({
      query: (localidadeId) => ({
        url: `/get-all-by-city`,
        body: JSON.stringify({
          localidadeId: localidadeId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['districts']
    }),
  })
})

export default districtApi
