import { createSlice } from "@reduxjs/toolkit"
import { IMailProps } from "./interfaces/IMailStore"
const initialState: IMailProps = {}

const mailsSlice = createSlice({
  name: "mails",
  initialState,
  reducers: {}
})

export default mailsSlice.reducer
