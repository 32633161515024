import { SEL_ARGUMENTS_AUTOCOMPLETE } from '../constants/storageNames'
import {
  USER_CLAIM_ROLE,
  USER_CLAIM_FULLNAME,
  USER_CLAIM_EMAIL,
  USER_CLAIM_ID,
  USER_CLAIM_NAME,
  USER_CLAIM_PHONENUMBER,
  USER_CLAIM_FAVORITES_ENTERPRISES,
  USER_CLAIM_TYPE_NAME_ROLE,
  USER_CLAIM_TYPE_NAME_FULLNAME,
  USER_CLAIM_TYPE_NAME_EMAIL,
  USER_CLAIM_TYPE_NAME_ID,
  USER_CLAIM_TYPE_NAME_NAME,
  USER_CLAIM_TYPE_NAME_PHONENUMBER,
  USER_CLAIM_TYPE_NAME_FAVORITES_ENTERPRISES
} from '../constants/userClaims'
import { IClaim } from '../store/reducers/interfaces/IResponseApi'

export const isValidStr = (str: string | null | undefined) => {
  return (
    typeof str == 'string' &&
    str != undefined &&
    str != null &&
    str.trim() != '' &&
    str != 'undefined'
  )
}

export function isValidObj<T>(obj: T) {
  return obj != undefined && JSON.stringify(obj) != '{}' && obj != null
}

export function isValidList<T>(list: T[] | undefined | null) {
  return list != undefined && list != null && list.length > 0
}
export function isValidData<T>(data: T[] | undefined | null, isLoading: boolean, isError: boolean) {
  return data != undefined && data != null && data.length > 0 && !isLoading && !isError
}
export function isValidSet<T>(list: Set<T> | undefined | null) {
  return list != undefined && list != null && list.size > 0
}
export const strToSlug = (str: string) => {
  const accents: { [key: string]: RegExp } = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g
  }
  for (let letter in accents) {
    str = str.replace(accents[letter], letter)
  }
  return str.replace(/\s+/g, '-').toLowerCase()
}

export function getIndexClaimByClaimName(claimName: string, userClaims: Record<number, IClaim>):number {
  let userClaimTypeName:string|undefined = undefined

  switch (claimName) {
    case USER_CLAIM_ROLE:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_ROLE
      break
    case USER_CLAIM_FULLNAME:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_FULLNAME
      break
    case USER_CLAIM_EMAIL:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_EMAIL
      break
    case USER_CLAIM_ID:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_ID
      break
    case USER_CLAIM_NAME:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_NAME
      break
    case USER_CLAIM_PHONENUMBER:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_PHONENUMBER
      break
    case USER_CLAIM_FAVORITES_ENTERPRISES:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_FAVORITES_ENTERPRISES
      break
    default:
      userClaimTypeName = undefined
  }

  var counter = 0
  for(const [key, val] of Object.entries(userClaims)) {
    if(val.Type == userClaimTypeName){
      return counter
    }
    counter += 1
  }
  return 0
}

export function getClaimByClaimName(claimName: string, userClaims: Record<number, IClaim>) {
  let userClaimTypeName:string|undefined = undefined
  let emptyClaim = {Type: "", Value: ""}

  switch (claimName) {
    case USER_CLAIM_ROLE:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_ROLE
      break
    case USER_CLAIM_FULLNAME:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_FULLNAME
      break
    case USER_CLAIM_EMAIL:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_EMAIL
      break
    case USER_CLAIM_ID:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_ID
      break
    case USER_CLAIM_NAME:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_NAME
      break
    case USER_CLAIM_PHONENUMBER:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_PHONENUMBER
      break
    case USER_CLAIM_FAVORITES_ENTERPRISES:
      userClaimTypeName = USER_CLAIM_TYPE_NAME_FAVORITES_ENTERPRISES
      emptyClaim.Value = "[]"
      break
    default:
      userClaimTypeName = undefined
  }

  if(userClaimTypeName != undefined){
    for(const [key, val] of Object.entries(userClaims)) {
      if(val.Type == userClaimTypeName){
        return val
      }
    }
  }
  return emptyClaim
}

export function validRequiredInputs() {
  let requiredElements = document.querySelectorAll(
    'form input[required]'
  ) as NodeListOf<HTMLInputElement>
  let validFields = true
  requiredElements.forEach((element) => {
    let valEl = element.value.replace(/\s/g, '')
    if (valEl == '' || valEl == undefined) {
      validFields = false
    }
  })
  return validFields
}

export function isCheckTermos() {
  const checkHTML = document.querySelector("button[role='checkbox']") as HTMLElement
  let valCheckHTML = 'false'
  if (checkHTML != undefined) {
    valCheckHTML = checkHTML.getAttribute('aria-checked') ?? 'false'
  } else {
    const inputCheckHTML = document.querySelector('input[type=checkbox]') as HTMLInputElement
    if (inputCheckHTML != undefined) valCheckHTML = inputCheckHTML.checked.toString()
  }
  return valCheckHTML == 'true'
}

export function convertListStrToStr(list: Array<string> | null) {
  let strList = isValidList(list) ? '[' : '[]'
  list?.forEach((item, index) => {
    strList += `"${item}"${index != list.length - 1 ? ',' : ']'}`
  })
  return strList
}

export const checkImageExists = (url: string): boolean => {
  let img = new Image()
  img.src = url
  return img.complete && img.naturalHeight !== 0
}

export function formatPhoneNumber(phoneNumber: string) {
  const phoneNumberLength = phoneNumber.length

  if (!phoneNumber.includes('(') && !phoneNumber.includes('-')) {
    if (phoneNumberLength === 10) {
      return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2, 1)} ${phoneNumber.substr(
        3,
        4
      )}-${phoneNumber.substr(7, 4)}`
    } else if (phoneNumberLength === 11) {
      return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2, 4)}-${phoneNumber.substr(6, 4)}`
    } else {
      return ''
    }
  }
}

export function convertString<T>(list: Array<T>) {
  let result = '['
  list.forEach((item) => {
    result += JSON.stringify(item)
  })
  result += ']'
  return result
}

export const filterCityUfLocal = (item: string) => {
  let cityUf = item.split('/')
  let city = ''
  let uf = ''
  if (cityUf.length >= 1) {
    city = cityUf[0].slice(0, cityUf[0].indexOf('['))
  }
  if (cityUf.length >= 2) {
    uf = cityUf[1].slice(0, cityUf[1].indexOf('['))
  }

  return `${city} / ${uf}`
}

export function separateList<T>(list: T[], separator: number): T[][] {
  let result: T[][] = []
  let temp: T[] = []
  let counter = 0

  for (let i = 0; i < list.length; i++) {
    temp.push(list[i])
    counter += 1
    if (counter == separator) {
      result.push(temp)
      temp = []
      counter = 0
    } else if (i == list.length - 1) {
      result.push(temp)
    }
  }
  return result
}

// Função genérica para salvar uma lista de objetos como string
export function saveListAsString<T>(list: T[]): string {
  let listString: string = '['
  list.forEach((item, index) => {
    listString += JSON.stringify(item)
    if (index < list.length - 1) {
      listString += ', '
    }
  })
  listString += ']'
  return listString
}

export function isMobile() {
  return window.screen.width < 769
}

export function isLocal() {
  return location.host.includes('127.0.0') || location.host == 'frontend-glemo.web21f104.uni5.net' || location.host.includes('localhost')
}

export const getPriceFormat = (value: number) => {
  let valFormat = value ? value.toString() : ""
  if (!value.toString().includes('.')) {
    valFormat = value.toFixed(2).replace('.', ',').toString()
  } else {
    valFormat = valFormat.replace('.', ',')
  }
  if (valFormat.length == 9) valFormat = `${valFormat.slice(0, 3)}.${valFormat.slice(3)}`
  return valFormat
}

export const cleanSelectedArgumentsForAutocomplete = () => {
  sessionStorage.removeItem(SEL_ARGUMENTS_AUTOCOMPLETE)
}

export function joinLists<T>(list1: T[], list2: T[]) {
  if (!list1 || !list2) {
    return undefined
  }

  const result = list1.reduce((acc, item1) => {
    const match = list2.find((item2) => item1 === item2)
    if (match) {
      acc.push({ ...item1, ...match })
    }
    return acc
  }, [] as T[])

  return result.length > 0 ? result : undefined
}

export const formatMoneyReal = (val: number) => {
  const formattedAmount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(val)
  return formattedAmount
}

export function formatBRL(valor: string | number | undefined): string {
  if(valor == undefined) return "N/I"
  if(typeof(valor) == 'string') return parseFloat(valor)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? "N/I";
  return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? "N/I";
}
