import { createSlice } from "@reduxjs/toolkit"
import { IContentProps } from "./interfaces/IContentStore"

const initialState: IContentProps = {}

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {}
})

export default tagsSlice.reducer
