import { useEffect, useState } from 'react'
import VoiceToText from '../../components/utilities/VoiceToText'
import MicrophoneIcon from '../../assets/icons/microphone-blue.svg'
import SkeletonLoading from '../../components/common/SkeletonLoading'
import { ModalErrorMicrophone } from '../Modais/ModalErrorAllowMicrophone'
import MicOff from '../../assets/icons/mic-off.svg'

interface Props {
  changeTranscript?: (transcript: string) => void
}

export default function Voice2Text({ changeTranscript }: Props) {
  const [startListen, setStartListen] = useState(false)
  const [stopListen, setStopListen] = useState(false)
  const [transcriptRealTime, setTranscriptRealTime] = useState('')
  const [transcript, setTranscript] = useState('')
  const [waitListen, setWaitListen] = useState(false)
  const [allowed, setAllowed] = useState(true)

  const [showModal, setShowModal] = useState(false)

  const handleTranscript = (val: string) => {
    if (changeTranscript != undefined) changeTranscript(val)
    setTranscriptRealTime(val)
  }

  const handleStopTranscript = () => {
    if (changeTranscript != undefined) changeTranscript(transcriptRealTime)
    setTranscript(transcriptRealTime)

    setStartListen(false)
    setStopListen(true)
  }

  const handleStartTranscript = () => {
    if (allowed) {
      setWaitListen(true)
      setStartListen(true)
      setTimeout(() => {
        setStopListen(false)
        setWaitListen(false)
      }, 1000)
    }
  }

  return (
    <div className='min-w-max'>
      {waitListen && allowed && (
        <>
          {/* <span className="font-bold text-[#666666]">Aguarde para começar...</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#5f6368"
            className="wait-time"
          >
            <path d="M320-160h320v-120q0-66-47-113t-113-47q-66 0-113 47t-47 113v120ZM160-80v-80h80v-120q0-61 28.5-114.5T348-480q-51-32-79.5-85.5T240-680v-120h-80v-80h640v80h-80v120q0 61-28.5 114.5T612-480q51 32 79.5 85.5T720-280v120h80v80H160Z" />
          </svg>
        </>
      )}
      {!startListen && allowed && (
        <button id="searchByVoice" value='Pesquisar por Voz' onClick={handleStartTranscript}>
          <img src={MicrophoneIcon} alt='Microfone Icon' width={20} className='max-w-fit' />
        </button>
      )}
      {!allowed && (
        <span className="cursor-pointer" onClick={() => setShowModal(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#f15c00"
          >
            <path d="m710-362-58-58q14-23 21-48t7-52h80q0 44-13 83.5T710-362ZM480-594Zm112 112-72-72v-206q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v126l-80-80v-46q0-50 35-85t85-35q50 0 85 35t35 85v240q0 11-2.5 20t-5.5 18ZM440-120v-123q-104-14-172-93t-68-184h80q0 83 57.5 141.5T480-320q34 0 64.5-10.5T600-360l57 57q-29 23-63.5 39T520-243v123h-80Zm352 64L56-792l56-56 736 736-56 56Z" />
          </svg>
        </span>
      )}

      {startListen && !stopListen && !waitListen && allowed && (
        <button id="searchByVoice" name='Pesquisar por Voz' disabled onClick={handleStopTranscript}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="red"
          >
            <path d="M480-280q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Zm0 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
          </svg>
          {/* <span className="font-bold text-[#666666]">Parar de gravar</span> */}
        </button>
      )}
      <VoiceToText
        startListen={startListen}
        stopListen={stopListen}
        stopTranscript={() => handleStopTranscript()}
        handleAllowed={(val: boolean) => setAllowed(val)}
        transcriptListen={(val: string) => handleTranscript(val)}
      />

      <ModalErrorMicrophone
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        title="Habilite seu microfone!"
        description="Para usar a Busca por Voz é necessário habilitar as permissões do seu microfone. Ao habilitar, atualize a página."
      />
    </div>
  )
}
