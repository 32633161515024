import { createSlice } from "@reduxjs/toolkit"
import { State } from "../../../interfaces/state"
import { IStateProps } from "./interfaces/IStateStore"

const initialState: IStateProps = {}

const statesFetchSlice = createSlice({
  name: "states",
  initialState,
  reducers: {}
})

export default statesFetchSlice.reducer
