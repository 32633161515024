import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IconTitle } from '../../../interfaces/enterprise/enterpriseIconTitle'

const differentialApi = createApi({
  reducerPath: 'differentialApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/differentials` }),
  tagTypes: ['differentials'],
  endpoints: (builder) => ({
    getAll: builder.mutation<DataRTKReturnList<IconTitle>, undefined>({
      query: () => ({
        url: `/get-all`,
        body: {
          title: "",
          activated: true
      },
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['differentials']
    })
  })
})

export default differentialApi
