import React, { useEffect, useState } from 'react'
import statusApi from '../../../store/reducers/status/statusApi'
import SkeletonLoading from '../../common/SkeletonLoading'
import { CategoryEnterprise } from '../../CategoryEnterprise'
import { Status } from '../../../interfaces/status'
import clsx from 'clsx'
import IconsText from '../../InternEnterprise/IconsText'
import { IconTitle } from '../../../interfaces/enterprise/enterpriseIconTitle'

import IconDifsalaojogos from '../../../assets/icons/dif-salao-jogos.svg'
import IconDifarcondicionado from '../../../assets/icons/dif-ar-condicionado.svg'
import IconDifbanheira from '../../../assets/icons/dif-banheira.svg'
import IconDifbicicletaeletrica from '../../../assets/icons/dif-bicicleta-eletrica.svg'
import IconDifbicicletario from '../../../assets/icons/dif-bicicletario.svg'
import IconDifchurrasqueira from '../../../assets/icons/dif-churrasqueira.svg'
import IconDifchuveirogas from '../../../assets/icons/dif-chuveiro-gas.svg'
import IconDifcloset from '../../../assets/icons/dif-closet.svg'
import IconDifdeckpiscina from '../../../assets/icons/dif-deck-piscina.svg'
import IconDifespacofamilia from '../../../assets/icons/dif-espaco-familia.svg'
import IconDifhallentrada from '../../../assets/icons/dif-hall-entrada.svg'
import IconDiflavanderia from '../../../assets/icons/dif-lavanderia.svg'
import IconDifparque from '../../../assets/icons/dif-parque.svg'
import { isValidStr } from '../../../common_functions'
import {
  DIFFERENTIALS_FILTER,
  FILTER_BATHROOMS,
  FILTER_DIFFERENTIALS,
  FILTER_LIST_STATUS,
  FILTER_NUMBER_BEDROOMS,
  FILTER_PARKING_SPACES,
  FILTER_SUIT,
  FILTER_area_MAX,
  FILTER_area_MIN,
  STATUS_FILTER
} from '../../../constants/storageNames'
import differentialApi from '../../../store/reducers/differentials/differentialApi'

const defaultStatus = ['PRONTO PARA MORAR', 'NA PLANTA', 'LANÇAMENTO', 'EM OBRAS']
const quantityListWith0 = ['0', '1', '2', '3', '4+']
const quantityList = ['1', '2', '3', '4+']

const dataDifferentialsStatic = [
  { Icon: IconDifsalaojogos, Title: 'Salão de jogos' },
  { Icon: IconDifarcondicionado, Title: 'Ar-Condicionado' },
  { Icon: IconDifbanheira, Title: 'Banheira' },
  { Icon: IconDifbicicletaeletrica, Title: 'Fitness' },
  { Icon: IconDifbicicletario, Title: 'Bicicletário' },
  { Icon: IconDifchurrasqueira, Title: 'Churrasqueira' },
  { Icon: IconDifchuveirogas, Title: 'Chuveiro a gás' },
  { Icon: IconDifcloset, Title: 'Closet' },
  { Icon: IconDifdeckpiscina, Title: 'Deck piscina' },
  { Icon: IconDifespacofamilia, Title: 'Espaço família' },
  { Icon: IconDifhallentrada, Title: 'Hall de entrada' },
  { Icon: IconDiflavanderia, Title: 'Lavanderia' },
  { Icon: IconDifparque, Title: 'Área externa' }
]

interface Props {
  btnIdHTML?: string
  defaultHidden?: boolean
  hiddenOverflowY?: boolean
  classListContainer?: string
}

export default function SectionFilterMobile({ btnIdHTML = undefined, classListContainer = '', defaultHidden = true, hiddenOverflowY = false }: Props) {
  const [show, setShow] = useState(false)
  const [useStatus, { data, isError, isLoading, isSuccess }] = statusApi.useGetStatusAllMutation()
  const [statusListSelected, setStatusListSelected] = useState<string[]>([])
  const [selectedBedroom, setSelectedBedroom] = useState('')
  const [selectedSuit, setSelectedSuit] = useState('')
  const [selectedBathroom, setSelectedBathroom] = useState('')
  const [selectedParkingSpace, setSelectedParkingSpace] = useState('')
  const [minArea, setMinArea] = useState('')
  const [maxArea, setMaxArea] = useState('')
  const [heightModal, setHeightModal] = useState('')
  const [selectedDifList, setSelectedDifList] = useState<string[]>([])
  const sessionDifferentials = sessionStorage.getItem(DIFFERENTIALS_FILTER)
  const sessionStatus = sessionStorage.getItem(STATUS_FILTER)
  const [dataIcons, setdataIcons] = useState<IconTitle[]>(
    sessionDifferentials != undefined ? JSON.parse(sessionDifferentials) : dataDifferentialsStatic
  )
  const [dataStatus, setDataStatus] = useState<Status[] | undefined>(
    sessionStatus != undefined ? JSON.parse(sessionStatus) : []
  )

  const [
    useDifferentials,
    { data: dataDif, isLoading: isLoadingDif, isSuccess: isSuccessDif, isError: isErrorDif }
  ] = differentialApi.useGetAllMutation()
  let listStatus: string[] = []

  const handleChangeSelectedItem = (item: IconTitle) => {
    if (selectedDifList?.includes(item.Title)) {
      setSelectedDifList(selectedDifList.filter((x) => x != item.Title))
    } else {
      setSelectedDifList((prev) => [...prev, item.Title])
    }
  }

  useEffect(() => {
    if (isSuccessDif) {
      sessionStorage.setItem(DIFFERENTIALS_FILTER, JSON.stringify(dataDif?.Data))
      setDataDifferentials()
    }
  }, [isSuccessDif])

  useEffect(() => {
    const btnFilterHTML = document.querySelector(
      '#' + (btnIdHTML != undefined ? btnIdHTML : 'openSectionFilter')
    ) as HTMLElement
    btnFilterHTML?.addEventListener('click', () => {
      setShow(!show)

      if (sessionDifferentials == undefined) {
        getDifferentials()
      }

      if (sessionStatus == undefined) {
        getStatus()
      }
    })
  }, [])

  useEffect(() => {
    if (isSuccess) {
      sessionStorage.setItem(STATUS_FILTER, JSON.stringify(data?.Data))
      setDataStatus(data?.Data)
    }
  }, [isSuccess])

  const getDifferentials = async () => {
    await useDifferentials(undefined)
  }

  const setDataDifferentials = () => {
    if (dataDif?.Data != undefined) setdataIcons(dataDif?.Data)
  }

  const cleanFilter = () => {
    setStatusListSelected([])
    setSelectedBathroom('')
    setSelectedBedroom('')
    setSelectedParkingSpace('')
    setSelectedSuit('')
    setMinArea('')
    setMaxArea('')
    setSelectedDifList([])
    listStatus = []

    let checkinputhtml = document.querySelectorAll('.check-status-filter')
    if (checkinputhtml != undefined) {
      checkinputhtml.forEach((item) => {
        let itemaux = item as HTMLInputElement
        itemaux.checked = false
      })
    }

    sessionStorage.setItem(FILTER_NUMBER_BEDROOMS, '')
    sessionStorage.setItem(FILTER_BATHROOMS, '')
    sessionStorage.setItem(FILTER_PARKING_SPACES, '')
    sessionStorage.setItem(FILTER_SUIT, '')
    sessionStorage.setItem(FILTER_area_MIN, '')
    sessionStorage.setItem(FILTER_area_MAX, '')
    sessionStorage.setItem(FILTER_DIFFERENTIALS, '')
    sessionStorage.setItem(FILTER_LIST_STATUS, '')
  }

  useEffect(() => {
    getStatus()
  }, [])

  // useEffect(() => {
  //   cleanFilter()
  // }, [])

  useEffect(() => {
    if (!isValidStr(heightModal)) {
      let containerHeader = document.querySelector('#mobileContainerSearch') as HTMLElement
      if (containerHeader != undefined) {
        setHeightModal((window.innerHeight - containerHeader.clientHeight) * 0.75 + 'px')
      }
    }
  }, [])

  const getStatus = async () => {
    await useStatus(undefined)
  }

  const handleChangeStatusListSelected = (status: string) => {
    if (listStatus.includes(status)) {
      listStatus.splice(listStatus.indexOf(status), 1)
    } else {
      listStatus.push(status)
    }
    sessionStorage.setItem(FILTER_LIST_STATUS, listStatus.toString())
  }

  useEffect(() => {
    sessionStorage.setItem(FILTER_NUMBER_BEDROOMS, selectedBedroom)
  }, [selectedBedroom])

  useEffect(() => {
    sessionStorage.setItem(FILTER_BATHROOMS, selectedBathroom)
  }, [selectedBathroom])

  useEffect(() => {
    sessionStorage.setItem(FILTER_PARKING_SPACES, selectedParkingSpace)
  }, [selectedParkingSpace])

  useEffect(() => {
    sessionStorage.setItem(FILTER_SUIT, selectedSuit)
  }, [selectedSuit])

  useEffect(() => {
    sessionStorage.setItem(FILTER_area_MIN, minArea)
  }, [minArea])

  useEffect(() => {
    sessionStorage.setItem(FILTER_area_MAX, maxArea)
  }, [maxArea])

  useEffect(() => {
    sessionStorage.setItem(FILTER_DIFFERENTIALS, selectedDifList.toString())
  }, [selectedDifList])

  return (
    <>
      {/* {show && ( */}
        <>
          <div id="sectionFilterMobile" className={`${defaultHidden ? 'hidden' : ''} ${classListContainer} pt-4`}>
            {defaultHidden && <hr className="mb-4 opacity-[0.2]" />}
            <div>
              <div className="text-center mt-4 pb-2">
                <button
                  className="text-glemo-blue font-semibold text-caption"
                  onClick={cleanFilter}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="inline-block me-2"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6601 3.2901L12.0901 7.8401L13.5201 9.2701C14.5501 7.9601 18.5001 2.9001 18.5001 2.9001C19.0101 2.2401 18.5401 1.2901 17.7101 1.2901H5.5401L7.5401 3.2901H15.6601Z"
                      fill="#0059FF"
                    />
                    <path
                      d="M1.5201 0.100098L0.100098 1.5101L8.7101 10.2901V16.2901C8.7101 16.8401 9.1601 17.2901 9.7101 17.2901H11.7101C12.2601 17.2901 12.7101 16.8401 12.7101 16.2901V14.1201L18.4901 19.9001L19.9001 18.4901L1.5201 0.100098Z"
                      fill="#0059FF"
                    />
                  </svg>
                  Limpar Filtros
                </button>
              </div>
            </div>
            <div style={{ overflowY: `${hiddenOverflowY ? "hidden" : "scroll"}`, height: heightModal }}>
              <div>
                <h5 className="font-semibold text-xl mt-4">Status do imóvel</h5>
                <div className="mt-5">
                  {isLoading && <SkeletonLoading height={30} />}
                  {isSuccess && dataStatus != undefined && dataStatus.length > 0 ? (
                    <div className="grid gap-3">
                      {dataStatus.map((item: Status, index) => {
                        return (
                          <div className="flex">
                            <input
                              type="checkbox"
                              value={item.Name}
                              onChange={() =>
                                handleChangeStatusListSelected(item.Name.toLowerCase())
                              }
                              className={
                                'me-3 h-[30px] my-auto ' + (index == 0 ? 'w-[25px]' : 'w-[30px]')
                              }
                            />
                            <CategoryEnterprise name={item.Name} className="w-[230px]" />
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <>
                      {!isLoading && (
                        <div className="grid gap-3">
                          {defaultStatus.map((item, index) => {
                            return (
                              <div className="flex">
                                <input
                                  type="checkbox"
                                  value={item}
                                  onChange={() =>
                                    handleChangeStatusListSelected(item.toLowerCase())
                                  }
                                  className={
                                    'me-3 h-[30px] my-auto ' +
                                    (index == 0 ? 'w-[25px]' : 'w-[30px]')
                                  }
                                />
                                <CategoryEnterprise name={item} className="w-full" />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </>
                  )}
                  {/* {isError && ( */}
                  {/* <div className="grid gap-3">
              {defaultStatus.map((item, index) => {
                return (
                  <div className="flex">
                    <input
                      type="checkbox"
                      value={item}
                      onChange={() => handleChangeStatusListSelected(item.toLowerCase())}
                      className={'me-3 h-[30px] my-auto ' + (index == 0 ? 'w-[25px]' : 'w-[30px]')}
                    />
                    <CategoryEnterprise name={item} className="w-full" />
                  </div>
                )
              })}
            </div> */}
                  {/* )} */}
                </div>
              </div>
              <hr className="mt-4 opacity-[0.2]" />
              <div className="grid">
                <div className=" mt-5  border-b border-[#C4C4C499] flex">
                  <div className=" my-auto pb-5">
                    <h3 className="font-semibold text-xl">Quartos</h3>
                    <div className="flex gap-3 mt-2">
                      {quantityList.map((item) => {
                        return (
                          <>
                            <button
                              className={clsx(
                                `rounded-full border border-[#C4C4C499] text-[#00000099] w-[42px] h-[42px] font-semibold`,
                                { 'bg-glemo-blue text-white': selectedBedroom == item }
                              )}
                              onClick={() => setSelectedBedroom(item)}
                            >
                              {item}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className=" mt-5  border-b border-[#C4C4C499] flex">
                  <div className=" my-auto pb-5">
                    <h3 className="font-semibold text-xl">Suítes</h3>
                    <div className="flex gap-3 mt-2">
                      {quantityListWith0.map((item) => {
                        return (
                          <>
                            <button
                              className={clsx(
                                `rounded-full border border-[#C4C4C499] text-[#00000099] w-[42px] h-[42px] font-semibold`,
                                { 'bg-glemo-blue text-white': selectedSuit == item }
                              )}
                              onClick={() => setSelectedSuit(item)}
                            >
                              {item}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className=" mt-5  border-b border-[#C4C4C499] flex">
                  <div className=" my-auto pb-5">
                    <h3 className="font-semibold text-xl">Banheiros</h3>
                    <div className="flex gap-3 mt-2">
                      {quantityList.map((item) => {
                        return (
                          <>
                            <button
                              className={clsx(
                                `rounded-full border border-[#C4C4C499] text-[#00000099] w-[42px] h-[42px] font-semibold`,
                                { 'bg-glemo-blue text-white': selectedBathroom == item }
                              )}
                              onClick={() => setSelectedBathroom(item)}
                            >
                              {item}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className=" mt-5  border-b border-[#C4C4C499] flex">
                  <div className=" my-auto pb-5">
                    <h3 className="font-semibold text-xl">Vagas</h3>
                    <div className="flex gap-3 mt-2">
                      {quantityListWith0.map((item) => {
                        return (
                          <>
                            <button
                              className={clsx(
                                `rounded-full border border-[#C4C4C499] text-[#00000099] w-[42px] h-[42px] font-semibold`,
                                { 'bg-glemo-blue text-white': selectedParkingSpace == item }
                              )}
                              onClick={() => setSelectedParkingSpace(item)}
                            >
                              {item}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className=" mt-5">
                  <div className=" my-auto pb-5">
                    <h3 className="font-semibold text-xl">Metragem</h3>
                    <div className="grid grid-cols-2 gap-3 mt-2">
                      <input
                        placeholder="Min."
                        type="number"
                        value={minArea}
                        onChange={(e) => setMinArea(e.target.value)}
                        className="border border-[#D4D4D8] focus:outline-0 placeholder:text-[#09101D] text-[#09101D] font-semibold rounded-lg  px-4 py-3 bg-[#F4F6F9]"
                      />
                      <input
                        placeholder="Max."
                        type="number"
                        value={maxArea}
                        onChange={(e) => setMaxArea(e.target.value)}
                        className="border border-[#D4D4D8] focus:outline-0  placeholder:text-[#09101D] text-[#09101D] font-semibold rounded-lg  px-4 py-3 bg-[#F4F6F9]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4 opacity-[0.2]" />
              <div>
                <h5 className="font-semibold text-xl mt-5">Diferenciais</h5>
                {isLoadingDif ? (
                  <div id="loadingBox" style={{ width: '50px', height: '50px' }}></div>
                ) : (
                  <IconsText
                    IconsTextList={dataIcons}
                    select
                    idHTML="navInternEnterpriseProximities"
                    title={''}
                    changeSelectedItem={(item) => handleChangeSelectedItem(item)}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      {/* )} */}
    </>
  )
}
