import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    id: 1,
    number_journeys: 5
  },
  {
    id: 2,
    number_journeys: 2
  },
  {
    id: 3,
    number_journeys: 1
  },
  {
    id: 4,
    number_journeys: 7
  }
]

const journeysSlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    addJourney: (state,action: PayloadAction<number>) => {
      state = state.map(item => {
        if(item.id === action.payload) item.number_journeys += 1
        return item
      })
    }
  }
})

export const {addJourney} = journeysSlice.actions
export default journeysSlice.reducer
