import { configureStore } from '@reduxjs/toolkit'
import enterprisesSlice from './reducers/enterprises'
import journeysSlice from './reducers/journeys'
import usersSlice from './reducers/users'
import userSlice from './reducers/users/user'
import searchSlice from './reducers/search'
import statusFetchSlice from './reducers/status/status'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
// import enterprisesCardsSlice from './reducers/enterprisesCards'
import stateApi from './reducers/state/stateApi'
import statesFetchSlice from './reducers/state/states'
import statusApi from './reducers/status/statusApi'
import enterpriseApi from './reducers/enterprise/enterpriseApi'
import cityApi from './reducers/city/cityApi'
import citiesFetchSlice from './reducers/city/cities'
import districtApi from './reducers/district/districtApi'
import districtFetchSlice from './reducers/district/districts'
import cardEnterpriseSlice from './reducers/cardsEnteprises/enterprises'
import filterEnterprisesSlice from './reducers/filterEnterprises'
import userApi from './reducers/users/userApi'
import accountApi from './reducers/accounts/accountApi'
import searchListLocals from './reducers/searchListLocals'
import builderApi from './reducers/builders/builderApi'
import buildersSlice from './reducers/builders/builders'
import mailApi from './reducers/mail/mailApi'
import mailsSlice from './reducers/mail/mails'
import leadApi from './reducers/leads/leadApi'
import leadsSlice from './reducers/leads/leads'
import pressRoomSlice from './reducers/pressrooms/pressrooms'
import pressroomApi from './reducers/pressrooms/pressroomApi'
import differentialsSlice from './reducers/differentials/differentials'
import differentialApi from './reducers/differentials/differentialApi'
import simulatorSlice from './reducers/simulators/simulators'
import simulatorApi from './reducers/simulators/simulatorApi'
import autocomplessSlice from './reducers/autocompletes/autocompletes'
import autocompleteApi from './reducers/autocompletes/autocompleteApi'
import campaignsSlice from './reducers/campaigns/campaigns'
import campaignApi from './reducers/campaigns/campaignApi'
import cardsEnterprisesIdsSlice from './reducers/cardsEnterprisesIds/cardsEnterprisesIds'
import cardEnterpriseIdsApi from './reducers/cardsEnterprisesIds/cardEnterpriseIdsApi'
import tagsSlice from './reducers/tags/tags'
import tagsApi from './reducers/tags/tagsApi'
import generalsSlice from './reducers/general/generals'
import generalApi from './reducers/general/generalApi'

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export const store = configureStore({
  reducer: {
    cardEnteprises: cardEnterpriseSlice,
    enterprises: enterprisesSlice,
    search: searchSlice,
    searchListLocals: searchListLocals,
    journeys: journeysSlice,
    user: userSlice,
    status: statusFetchSlice,
    states: statesFetchSlice,
    districts: districtFetchSlice,
    cities: citiesFetchSlice,
    filterEnterprises: filterEnterprisesSlice,
    builder: buildersSlice,
    mails: mailsSlice,
    leads: leadsSlice,
    pressRoom: pressRoomSlice,
    differentials: differentialsSlice,
    simulators: simulatorSlice,
    autocompletes: autocomplessSlice,
    campaigns: campaignsSlice,
    cardsEnterprisesIds: cardsEnterprisesIdsSlice,
    tags: tagsSlice,
    generals: generalsSlice,
    [simulatorApi.reducerPath]: simulatorApi.reducer,
    [pressroomApi.reducerPath]: pressroomApi.reducer,
    [stateApi.reducerPath]: stateApi.reducer,
    [statusApi.reducerPath]: statusApi.reducer,
    [enterpriseApi.reducerPath]: enterpriseApi.reducer,
    [cityApi.reducerPath]: cityApi.reducer,
    [districtApi.reducerPath]: districtApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [builderApi.reducerPath]: builderApi.reducer,
    [mailApi.reducerPath]: mailApi.reducer,
    [leadApi.reducerPath]: leadApi.reducer,
    [differentialApi.reducerPath]: differentialApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [cardEnterpriseIdsApi.reducerPath]: cardEnterpriseIdsApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      stateApi.middleware,
      statusApi.middleware,
      enterpriseApi.middleware,
      cityApi.middleware,
      districtApi.middleware,
      userApi.middleware,
      accountApi.middleware,
      builderApi.middleware,
      mailApi.middleware,
      leadApi.middleware,
      pressroomApi.middleware,
      differentialApi.middleware,
      simulatorApi.middleware,
      autocompleteApi.middleware,
      campaignApi.middleware,
      cardEnterpriseIdsApi.middleware,
      tagsApi.middleware,
      generalApi.middleware
    ])
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

