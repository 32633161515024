import { TextInput } from '../common'
import { useEffect, useState } from 'react'
import { FILTER_ADDRESS, FULL_TEXT, LOCATIONS } from '../../constants/storageNames'
import {
  cleanSelectedArgumentsForAutocomplete,
  isMobile,
  isValidList,
  isValidStr
} from '../../common_functions'
import SkeletonLoading from '../common/SkeletonLoading'
import { Cookies } from 'react-cookie'
import { codeCityUrl, codeFulltext, codeUfUrl } from '../../constants/searchEnteprises'
import React from 'react'
import SeparatorMicrophoneGray from '../../assets/icons/separator-microphone-gray.svg'
import Voice2Text from '../Voice2Text'
import SearchAutocomplete from '../SearchAutocomplete'
import { Argument } from '../../interfaces/arguments'

export function BusqueCidadeEstadoBairro() {
  const cookies = new Cookies()
  const [originalList, setOriginalList] = useState<string[]>([])
  const [input, setInput] = useState<string>(sessionStorage.getItem(FULL_TEXT) ?? '')
  const [keyPress, setKeyPress] = useState<string>('')
  const [focusOut, setFocusOut] = useState(false)
  const [selectedArguments, setSelectedArguments] = useState<Argument[]>()

  // useEffect(() => {
  //   setInput(sessionStorage.getItem(FULL_TEXT) ?? "")
  // }, [location])

  const handleInput = (val: string) => {
    setInput(val)
    sessionStorage.setItem(FULL_TEXT, val)
  }

  const searchUrlByDataEnderecoHTML = () => {
    let input =
      document.querySelectorAll('#enderecoBuscaEmpreendimento').length > 1
        ? document.querySelectorAll('#enderecoBuscaEmpreendimento')[1]
        : document.querySelectorAll('#enderecoBuscaEmpreendimento')[0]
    let dataEndereco = ''
    if (window.screen.width >= 768) {
      dataEndereco = (document.querySelector('#enderecoBuscaEmpreendimento') as HTMLInputElement)
        .value
    } else {
      dataEndereco = (input as HTMLInputElement).value
    }

    localStorage.setItem(FILTER_ADDRESS, dataEndereco)
    let searchUrl = '?search=enterprises'
    if (isValidStr(dataEndereco)) {
      if (dataEndereco.includes('/')) {
        let addressCityState = dataEndereco?.split('/') ?? ''
        searchUrl += `&${codeCityUrl}${addressCityState[0]}`
        searchUrl += `&${codeUfUrl}${addressCityState[1]}`
      } else {
        searchUrl += `&${codeFulltext}${dataEndereco}`
      }
    }
    cleanSelectedArgumentsForAutocomplete()
    window.location.href = `/busca/${searchUrl}`
  }

  const handleSearchEnterprises = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      searchUrlByDataEnderecoHTML()
    }
  }

  const handleFocusInput = () => {
    let enderecoBuscaEmpreendimentoHTML = document.querySelector(
      '#enderecoBuscaEmpreendimento'
    ) as HTMLElement
    if (enderecoBuscaEmpreendimentoHTML != undefined && !isValidList(originalList))
      updateListOriginByCookies()
  }

  const updateListOriginByCookies = () => {
    let locationsCookies = cookies.get(LOCATIONS)?.split(',') ?? ''
    setOriginalList(locationsCookies)
  }

  useEffect(() => {
    if (!isValidList(originalList)) {
      updateListOriginByCookies()
    }
  }, [location.pathname])

  const handleChangeTranscript = (transcript: string) => {
    setInput(transcript.trim())
  }

  // useEffect(() => {
  //   let enderecoBuscaEmpreendimentoHTML = document.querySelector(
  //     '#enderecoBuscaEmpreendimento'
  //   ) as HTMLElement
  //   if (enderecoBuscaEmpreendimentoHTML != undefined && !isValidList(originalList))
  //     enderecoBuscaEmpreendimentoHTML.addEventListener('focus', updateListOriginByCookies)
  // }, [])

  return (
    <>
      {(
        <>
          <TextInput.Root className={isMobile() ? 'border-none' : ''}>
            {!isMobile() && (
              <TextInput.Icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="32"
                  height="32"
                  viewBox="0 0 256 256"
                >
                  <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                </svg>
              </TextInput.Icon>
            )}
            <TextInput.Input
              id="enderecoBuscaEmpreendimento"
              className={'text-blue-700 md:text-sm xl:text-2xl enderecoBuscaEmpreendimento ' + isMobile() ? 'w-[inherit]' : ''}
              placeholder="Busque seu imóvel aqui"
              value={input}
              onFocus={() => handleFocusInput()}
              onChange={(e) => handleInput(e.target.value)}
              onKeyUp={(e) => handleSearchEnterprises(e)}
            />
            <TextInput.Icon className="min-w-max">
              <img src={SeparatorMicrophoneGray} className="w-1" />
            </TextInput.Icon>
            <TextInput.Icon>
              <Voice2Text
                changeTranscript={(transcript: string) => handleChangeTranscript(transcript)}
              />
            </TextInput.Icon>
          </TextInput.Root>
          <div className="hidden md:block">
            <SearchAutocomplete
              classNameSuggestionsContainer="left-[172px] top-[67px]"
              onFocusOutInput={focusOut}
              classNameSearchHTML='enderecoBuscaEmpreendimento'
              changedInputValue={input}
              onInputKeydown={keyPress}
              handleSearch={searchUrlByDataEnderecoHTML}
              changeInputValue={(val) => setInput(val)}
              changeSelectedArguments={(args) => setSelectedArguments(args)}
            />
          </div>
          <div className="md:hidden">
            <SearchAutocomplete
              classNameSuggestionsContainer="left-[16px] top-[129px]"
              onFocusOutInput={focusOut}
              changedInputValue={input}
              onInputKeydown={keyPress}
              handleSearch={searchUrlByDataEnderecoHTML}
              changeInputValue={(val) => setInput(val)}
              changeSelectedArguments={(args) => setSelectedArguments(args)}
            />
          </div>
        </>
      ) || <SkeletonLoading />}
    </>
  )
}
