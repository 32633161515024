import { createSlice } from "@reduxjs/toolkit"
import { IStatusProps } from "./interfaces/IStatusStore"

const initialState: IStatusProps = {}

const statusFetchSlice = createSlice({
  name: "status",
  initialState,
  reducers: {}
})

export default statusFetchSlice.reducer
