import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import './styles/global.css'
import './styles/tinySlider.css'
import 'tailwindcss/tailwind.css'
import { store } from './store'
import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<div id="loadingBox"></div>}><App /></Suspense>
    </Provider>
  </React.StrictMode>
)
