import { createSlice } from "@reduxjs/toolkit"
import { IAutocompleteProps } from "./interfaces/IAutocompleteStore"

const initialState: IAutocompleteProps = {}

const autocompletesSlice = createSlice({
  name: "autocomplete",
  initialState,
  reducers: {}
})

export default autocompletesSlice.reducer
