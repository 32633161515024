import React from 'react'
import { Button, TextInput } from '../../common'
import BgBestProperties from '../../../assets/banner-best-properties-mobile.webp'
import FilterIcon from '../../../assets/icons/icon-filter-blue.svg'
import ModalFilter from '../../Modais/ModalFilter'
import { Suspense, memo, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import SkeletonLoading from '../../common/SkeletonLoading'
import {
  FILTER_NUMBER_BEDROOMS,
  FILTER_PRICE_MAX,
  FILTER_PRICE_MIN,
  HISTORICO_BUSCAS,
  LOCATIONS,
  SEL_ARGUMENTS_AUTOCOMPLETE
} from '../../../constants/storageNames'
import { Cookies } from 'react-cookie'
import { isMobile, isValidList, isValidStr } from '../../../common_functions'
import search from '../../../store/reducers/search'
import MicrophoneIcon from '../../../assets/icons/microphone-blue.svg'
import SeparatorMicrophoneGray from '../../../assets/icons/separator-microphone-gray.svg'
import { CardListLocals } from '../../Cards/CardListLocals'
import clsx from 'clsx'
import Voice2Text from '../../Voice2Text'
import SearchAutocomplete from '../../SearchAutocomplete'
import { Argument } from '../../../interfaces/arguments'

function SectionBestPropertiesFullTextMobile() {
  const [showModal, setShowModal] = useState(false)
  const [valueInput, setValueInput] = useState('')
  const [keyPress, setKeyPress] = useState<string>('')
  const [selectedArguments, setSelectedArguments] = useState<Argument[]>()
  const [focusOut, setFocusOut] = useState(false)

  const cookies = new Cookies()
  const [originalList, setOriginalList] = useState<string[]>([])

  const minValorImovelLocal = localStorage.getItem(FILTER_PRICE_MIN) ?? ''
  const maxValorImovelLocal = localStorage.getItem(FILTER_PRICE_MAX) ?? ''
  const quartosLocal = localStorage.getItem(FILTER_NUMBER_BEDROOMS) ?? undefined

  const updateListOriginByCookies = () => {
    let locationsCookies = cookies.get(LOCATIONS)?.split(',') ?? ''
    setOriginalList(locationsCookies)
  }

  useEffect(() => {
    if (!isValidList(originalList)) {
      updateListOriginByCookies()
    }
  }, [location.pathname])

  useEffect(() => {
    let enderecoBuscaEmpreendimentoHTML = document.querySelector(
      '#enderecoBuscaEmpreendimento'
    ) as HTMLElement
    if (enderecoBuscaEmpreendimentoHTML != undefined && !isValidList(originalList))
      enderecoBuscaEmpreendimentoHTML.addEventListener('focus', updateListOriginByCookies)
  }, [])

  const updateSelectedArguments = () => {
    let updatedSelectedArguments = selectedArguments
    selectedArguments?.forEach(element => {
      if(!valueInput.toLowerCase().includes(element.Title.toLowerCase())){
        updatedSelectedArguments = updatedSelectedArguments?.filter(x => x != element)
      }
    });
    sessionStorage.setItem(SEL_ARGUMENTS_AUTOCOMPLETE, JSON.stringify(updatedSelectedArguments))
  }

  const handleSearch = () => {
    const locals = sessionStorage.getItem(HISTORICO_BUSCAS)
    sessionStorage.setItem(HISTORICO_BUSCAS, `${locals}&${search}`)
    updateSelectedArguments()
    searchEnterprise()
  }

  const searchEnterprise = () => {
    let strBuscaURL = `?search=enterprises&fullText=${valueInput}`
    window.location.href = `/busca/${strBuscaURL}`
    // let strBuscaURL = '?search=filter'
    // if (isValidStr(valueInput)) {
    //   strBuscaURL += `&fullTextWithFilter=${valueInput}`
    // }
    // if (isValidStr(minValorImovelLocal)) {
    //   strBuscaURL += `&priceMinimumValue=${minValorImovelLocal}`
    // }
    // if (isValidStr(maxValorImovelLocal)) {
    //   strBuscaURL += `&priceMaximumValue=${maxValorImovelLocal}`
    // }
    // if (quartosLocal != undefined) {
    //   strBuscaURL += `&numberOfRooms=${quartosLocal}`
    // }
    // window.location.href = `/busca/${strBuscaURL}`
  }

  const handleChangeTranscript = (transcript: string) => {
    setValueInput(transcript.trim())
  }

  return (
    <section className="section-best-properties h-[450px] md:aspect-auto flex">
      <div
        style={{ backgroundImage: `url(${BgBestProperties})` }}
        className="w-full h-auto bg-cover bg-no-repeat bg-center "
      >
        <div
          id="containerSearchProperty"
          data-padding={40}
          className={
            'container-search-property max-w-[1000px] rounded-md mx-5 lg:mx-auto md:w-4/5 m-auto text-center py-6 px-4 shadow-[0px_4px_24px_rgba(0,0,0,0.12)] relative -bottom-[16rem] left-0 right-0'
          }
          style={{ backgroundColor: `rgba(255,255,255,0.9)` }}
        >
          <hr className="h-[4px] w-[180px] max-w-full bg-[#F15C00] text-transparent" />
          <h2 className="text-2xl text-black mt-5">
            Busque seu <span className="font-semibold">novo imóvel</span> <br />
            <span className="font-bold text-[#0059FF]">no glemO</span>
          </h2>
          <div className="w-full mt-2">
            <div>
              <div className="flex px-2 rounded text-md outline-none placeholder:text-gray-600 max-w-100 bg-[#C4C4C433]">
                <TextInput.Root className="border-none bg-transparent">
                  <TextInput.Input
                    value={valueInput}
                    onBlur={() => setFocusOut(true)}
                      onFocus={() => setFocusOut(false)}
                    onChange={(e) => setValueInput(e.target.value)}
                    className={`w-full bg-transparent`}
                    placeholder={'Faça sua busca'}
                  />
                </TextInput.Root>
                <div className="text-center m-auto pe-2">
                  <img src={SeparatorMicrophoneGray} />
                </div>
                <div className="text-center m-auto">
                  <Voice2Text
                    changeTranscript={(transcript: string) => handleChangeTranscript(transcript)}
                  />
                </div>
              </div>
              <SearchAutocomplete
                onFocusOutInput={focusOut}
                changedInputValue={valueInput}
                handleSearch={handleSearch}
                onInputKeydown={keyPress}
                changeInputValue={(val) => setValueInput(val)}
                changeSelectedArguments={(args) => setSelectedArguments(args)}
              />
            </div>
          </div>
          <div className="w-full m-auto mt-2">
            <div className="grid grid-cols-1 gap-3">
              <div className="text-center m-auto">
                <Button
                  id="searchBestProperties"
                  variant="primary"
                  onClick={handleSearch}
                  className="font-bold text-xl w-[200px] h-full"
                >
                  BUSCAR
                </Button>
              </div>
            </div>
          </div>
          <ModalFilter
            show={showModal}
            close={() => setShowModal(false)}
            elementInputCityId="inputSearchEnterprisesSectionBestProperties"
          />
        </div>
      </div>
    </section>
  )
}
export default memo(SectionBestPropertiesFullTextMobile)
