import React, { useEffect, useState } from 'react'
import autocompleteApi from '../../store/reducers/autocompletes/autocompleteApi'
import { Argument } from '../../interfaces/arguments'
import { ARGUMENTS_AUTOCOMPLETE } from '../../constants/storageNames'
import { isMobile } from '../../common_functions'
import { Link, useNavigate } from 'react-router-dom'
import BannerNotFound from '../../assets/Image-not-found.webp'

interface Props {
  changedInputValue: string
  onInputKeydown?: string
  changeInputValue: (inputValue: string) => void
  changeSelectedArguments: (args: Argument[]) => void
  handleSearch?: () => void
  onFocusOutInput: boolean
  classNameSuggestionsContainer?: string
  classNameSearchHTML?: string
  isVoiceTxt?: boolean
}

export default function SearchAutocomplete({
  changedInputValue,
  onInputKeydown,
  changeInputValue,
  changeSelectedArguments,
  classNameSuggestionsContainer = '',
  onFocusOutInput,
  classNameSearchHTML,
  handleSearch,
  isVoiceTxt = false
}: Props) {
  // const suggestions: Suggestion[] = [
  //   { label: 'Apple', name: 'Apple' },
  //   { label: 'Banana', name: 'Banana' },
  //   { label: 'Cherry', name: 'Cherry' },
  //   { label: 'Date', name: 'Date' },
  //   { label: 'Fig', name: 'Fig' },
  //   { label: 'Grape', name: 'Grape' },
  //   { label: 'Kiwi', name: 'Kiwi' }
  // ]
  const [inputValue, setInputValue] = useState<string>('')
  const [filteredSuggestions, setFilteredSuggestions] = useState<Argument[]>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [clickSuggestion, setClickSuggestion] = useState(false)
  const [widthSuggestions, setWidthSuggestions] = useState(300)
  const argumentsAutocomplete = sessionStorage.getItem(ARGUMENTS_AUTOCOMPLETE)
  const [suggestions, setSuggestion] = useState<Argument[]>(
    JSON.parse(argumentsAutocomplete ?? '[]')
  )
  const [selectedArguments, setSelectedArguments] = useState<Argument[]>([])
  const { data, isLoading, isError, isSuccess, refetch } =
    autocompleteApi.useGetArgumentsQuery(undefined)
  const maxArguments = 20
  const keyWords = ['de', 'em', 'na', 'no', 'apartamento', 'ape', 'apto']
  const transcriptTextInt = { um: 1, dois: 2, tres: 3, três: 3, quatro: 4, cinco: 5 }

  const changeWidthSuggestionsContainer = () => {
    if (classNameSearchHTML) {
      var html = document.querySelector('.' + classNameSearchHTML)?.clientWidth
      if (html) setWidthSuggestions(html)
    }
  }

  useEffect(() => {
    // handleChange(changedInputValue)
    handleChangeVoice(changedInputValue)
  }, [changedInputValue])

  useEffect(() => {
    if (onFocusOutInput)
      setTimeout(() => {
        setShowSuggestions(false)
      }, 500) // tempo de segurança caso seja um handleclick
  }, [onFocusOutInput])

  useEffect(() => {
    if (showSuggestions) {
      changeWidthSuggestionsContainer()
    }
  }, [showSuggestions])

  useEffect(() => {
    if (onInputKeydown != undefined) handleKeyPress(onInputKeydown)
  }, [onInputKeydown])

  useEffect(() => {
    if (isSuccess && suggestions.length < 1) {
      sessionStorage.setItem(ARGUMENTS_AUTOCOMPLETE, JSON.stringify(data))
      setSuggestion(data)
    }
  }, [isSuccess])

  useEffect(() => {
    suggestions.forEach(element => {
      if(element.TypeId == 5 && !element.Image){
        setSuggestion(suggestions.filter(x => x != element))
      }
    });
  }, [suggestions])

  useEffect(() => {
    changeSelectedArguments(selectedArguments)
  }, [selectedArguments])

  const handleChangeVoice = (val: string) => {
    const userInput = val
    setInputValue(userInput)
    let split = userInput.split(' ')
    let splitFilter = split.filter((x) => !keyWords.includes(x.toLowerCase()))

    // transcrever escrita para numero para retornar tanto 'ap 2 quartos' como 'ap dois quartos'
    Object.entries(transcriptTextInt).forEach(([key, value]) => {
      if (splitFilter.includes(key)) {
        splitFilter = splitFilter.filter((x) => x != key)
        splitFilter.push(value.toString())
      }
    })

    if (((isSuccess && data != undefined) || suggestions.length > 0) && !clickSuggestion) {
      let filtered = suggestions

      let results: number[] = []

      // add o numero de retornos nos resultados p/ nao ocorrer o problema de retornar 0 elementos
      // por causa de uma palavra-chave
      splitFilter.forEach((element) => {
        results.push(suggestions.filter((x) => x.Title.toLowerCase().includes(element.toLowerCase())).length)
      })

      // if (results.includes(0) && results.filter((x) => x > 0).length > 0) {
      //   splitFilter.forEach((element, index) => {
      //     if (results[index] != 0) {
      //       filtered = filtered.filter((item) => item.Title.toLowerCase().includes(element.toLowerCase()))
      //     }
      //   })
      // } else {
      //   splitFilter.forEach((element, index) => {
      //     filtered = filtered.filter((item) => item.Title.toLowerCase().includes(element.toLowerCase()))
      //   })
      // }

      //caso nos resultados tiver 0 elementos retornados, então esse elemento nao sera contado na pesquisa
      splitFilter.forEach((element, index) => {
        if (results[index] != 0) {
          filtered = filtered.filter((item) => item.Title.toLowerCase().includes(element.toLowerCase()))
        }
      })

      // se o retorno for 0 então nao vai mais filtrando a cada elemento e sim concatenando cada resultado de cada elemento
      if(filtered.length < 1){
        let filteredAux:Argument[] = []
        splitFilter.forEach((element, index) => {
          filteredAux = filteredAux.concat(suggestions.filter((item) => item.Title.toLowerCase().includes(element.toLowerCase())))
        })
      }

      // distinct elementos
      filtered = [...new Set(filtered)]

      // ordenacao para deixar os produtos no topo
      filtered = sortProdutsFirst(filtered)

      setFilteredSuggestions(filtered)
      setShowSuggestions(true)
    }
    if (clickSuggestion) setClickSuggestion(false)
  }

  const handleChange = (val: string) => {
    const userInput = val
    setInputValue(userInput)
    let split = userInput.split(' ')

    if (((isSuccess && data != undefined) || suggestions.length > 0) && !clickSuggestion) {
      let filtered = suggestions.filter(
        (suggestion) =>
          //pega pela ultima palavra-chave
          suggestion.Title.toLowerCase().indexOf(split[split.length - 1].toLowerCase()) > -1
        // (suggestion) => suggestion.Title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      )
      filtered = sortProdutsFirst(filtered)

      setFilteredSuggestions(filtered)
      setShowSuggestions(true)
    }
    if (clickSuggestion) setClickSuggestion(false)
  }

  const sortProdutsFirst = (filtered: Argument[]) => {
    return filtered.sort((a, b) => {
      if (a.Type === 'Product' && b.Type !== 'Product') {
        return -1
      } else if (a.Type !== 'Product' && b.Type === 'Product') {
        return 1
      } else {
        return 0
      }
    })
  }

  const handleClick = (suggestion: Argument) => {
    if (suggestion.Type == 'Product' && suggestion.Slug) {
      window.location.href = '/empreendimentos/' + suggestion.Slug
    } else {
      const split = inputValue.split(' ')
      let newVal = suggestion.Title
      let splitSuggestion = suggestion.Title.toLowerCase().split(' ')
      if (split.length > 1) {
        newVal = ''
        split.slice(0, split.length - 1).forEach((txt, index) => {
          // para nao repetir: exemplo de repeticao "aptos em São São Paulo"
          if (index >= split.length - 1 - splitSuggestion.length + 1) {
            if (!splitSuggestion.includes(txt.toLowerCase())) {
              newVal += (index == 0 ? '' : ' ') + txt
            }
          } else {
            newVal += (index == 0 ? '' : ' ') + txt
          }
        })
        newVal += ' ' + suggestion.Title
      }

      setSelectedArguments((prev) => [...prev, suggestion])

      setClickSuggestion(true)
      setInputValue(newVal)
      changeInputValue(newVal)
      setFilteredSuggestions([])
      setShowSuggestions(false)
    }
  }

  const handleKeyPress = (key: string) => {
    if (key === 'Enter' || key === 'Tab') {
      setShowSuggestions(false)
    }
  }

  const seeMoreResults = () => {
    if (handleSearch) handleSearch()
  }

  const renderSuggestions = () => {
    // if (true) {
    //   if (true) {
    if (showSuggestions && inputValue) {
      if (filteredSuggestions.length) {
        return (
          <div
            className={`suggestions bg-white shadow-xl rounded-b-2xl mt-2 absolute z-[1000] max-w-full text-start py-2 ${classNameSuggestionsContainer}`}
            style={{ width: `${widthSuggestions}px` }}
          >
            <ul className="max-h-[250px] overflow-y-auto">
              {filteredSuggestions.slice(0, maxArguments).map((suggestion, index) => (
                <>
                  <li
                    key={index}
                    className="cursor-pointer px-4 py-2 hover:bg-[#F2F4F8]"
                    onClick={() => handleClick(suggestion)}
                  >
                    <div className="flex">
                      <div className="my-auto">
                        {suggestion.Type == 'Product' ? (
                          <>
                            <div
                              className="bg-center bg-no-repeat bg-cover w-[50px] lg:w-[80px] rounded-md aspect-[108/72]"
                              style={{
                                backgroundImage: `url('${suggestion.Image ?? BannerNotFound}')`
                              }}
                            ></div>
                          </>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            width={isMobile() ? '18px' : '24px'}
                            fill="#5f6368"
                          >
                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                          </svg>
                        )}
                      </div>
                      <div className="ps-4">
                        <h5 className="font-[900] text-sm md:text-base">{suggestion.Title}</h5>
                        <small className="text-xs md:text-sm">
                          {suggestion.Label == 'Produto' ? 'Empreendimento' : suggestion.Label}
                        </small>
                      </div>
                    </div>
                  </li>
                  {/* {index < filteredSuggestions.length - 1 && <hr className="my-2 opacity-[0.1]" />} */}
                </>
              ))}
            </ul>
            <hr className="my-2 opacity-[0.1]" />
            <div className="lg:grid lg:grid-cols-3 py-2 px-4">
              <div className="col-span-1 text-center lg:text-start">
                <h6 className="text-[#999FAA] text-base">
                  {filteredSuggestions ? filteredSuggestions.length : 0} resultados
                </h6>
              </div>
              <div className="text-center lg:text-end mt-2 lg:mt-0 col-span-2">
                <h6
                  className="cursor-pointer font-semibold text-base underline underline-offset-2"
                  onClick={seeMoreResults}
                >
                  Ver mais resultados{' '}
                  <span>
                    <svg
                      width="14"
                      viewBox="0 0 15 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block -mt-1 ms-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.56574 0.238643L13.8066 5.42386C14.1283 5.74205 14.1283 6.25794 13.8066 6.57614L8.56574 11.7614C8.24413 12.0795 7.7227 12.0795 7.40109 11.7614C7.07948 11.4432 7.07948 10.9273 7.40109 10.6091L11.2361 6.81478H0.0478516V5.18522H11.2361L7.40109 1.39091C7.07948 1.07272 7.07948 0.556834 7.40109 0.238643C7.7227 -0.0795478 8.24413 -0.0795478 8.56574 0.238643Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </h6>
              </div>
            </div>
          </div>
        )
      }
    }
    return null
  }

  return <div className="autocomplete">{renderSuggestions()}</div>
}
