import { createSlice } from "@reduxjs/toolkit"
import { IPressroomProps } from "./interfaces/IPressroomStore"

const initialState: IPressroomProps = {}

const pressroomSlice = createSlice({
  name: "pressroom",
  initialState,
  reducers: {}
})

export default pressroomSlice.reducer
