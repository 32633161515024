import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../../constants/baseUrlApiStatic";


export const fetchCardsEnterprises = createAsyncThunk(
  'api/fetchCardsEnterprises',
  async (arg:Object, {dispatch, getState}) => {
    return await fetch(`${API_URL}/glemo/enterprises/cards`, {
      body: JSON.stringify(arg),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Data ?? []
      })
  }
)

interface IInitialState {
  items: []
  status: 'loading'|'pending'|'success' | 'failed'
}

const initialState:IInitialState = {
  items: [],
  status: 'pending'
}

const cardEnterpriseSlice = createSlice({
  name: 'cardEnterprise',
  initialState,
  reducers:{
    enteprisesFilter: (state, action) => {
      state.items = action.payload
    },
    defaultInitialState: (state) => (state =initialState)
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCardsEnterprises.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(fetchCardsEnterprises.fulfilled, (state, {payload}) => {
      state.items = payload
      state.status = 'success'
    })
    builder.addCase(fetchCardsEnterprises.rejected, (state, action) => {
      state.status = 'failed'
    })
  }
})

export default cardEnterpriseSlice.reducer
export const {
  defaultInitialState
} = cardEnterpriseSlice.actions
