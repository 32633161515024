import { createSlice } from "@reduxjs/toolkit"
import { IBuilderProps } from "./interfaces/IBuilderStore"

const initialState: IBuilderProps = {}

const buildersSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {}
})

export default buildersSlice.reducer
