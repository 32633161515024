import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { District } from '../../../interfaces/district'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IProductSearchedViewed, IProductSearchedViewedUpdt } from '../../../interfaces/productSearchedViewed'

const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['generals'],
  endpoints: (builder) => ({
    addProductSearchedViewed: builder.mutation<DataRTKReturn<string>, IProductSearchedViewed>({
      query: (content) => ({
        url: `/product-searched-viewed/add`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    getProductSearchedViewed: builder.query<DataRTKReturn<string>, string>({
      query: (id) => `/product-searched-viewed/get-by-id/${id}`
    }),
    updtProductSearchedViewed: builder.mutation<string, IProductSearchedViewedUpdt>({
      query: (content) => ({
        url: `/product-searched-viewed/update`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
  })
})

export default generalApi
