import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { State } from '../../../interfaces/state'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { ILead } from '../../../interfaces/lead'
import { ILeadAnswer, ILeadJourneyStep, ILeadQuestion } from '../../../interfaces/leads/ileads'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'

interface bodyCountryName {
  countryId: string
  name: string
}

const leadApi = createApi({
  reducerPath: 'leadApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['leads'],
  endpoints: (builder) => ({
    addLead: builder.mutation<IDefaultResponse, ILead>({
      query: (content) => ({
        url: `/glemo/leads/add`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['leads']
    }),
    createAnswerJourney: builder.mutation<IDefaultResponse, ILeadAnswer>({
      query: (content) => ({
        url: `/leads/create-leads-answer`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['leads']
    }),
    getLeadsJourney: builder.mutation<DataRTKReturnList<Enterprise2>, string>({
      query: (email) => ({
        url: `/glemo/leads-jorney`,
        body: JSON.stringify({
          email: email,
          pageSize: null,
          pageIndex: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['leads']
    }),
    getLeadJourneyCompletionStatus: builder.query<DataRTKReturnList<ILeadJourneyStep>, string>({
      query: (enterpriseId) => `/leads/get-lead-journey-completion-status/${enterpriseId}`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
    getQuestionsJourney: builder.query<DataRTKReturnList<ILeadQuestion>, undefined>({
      query: () => `/leads/get-leads-questions`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
    getAnswersJourney: builder.query<DataRTKReturn<ILeadAnswer>, string>({
      query: (id) => `/leads/get-leads-answers-by-lead/${id}`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
  })
})

export default leadApi
