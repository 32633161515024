import { createSlice } from "@reduxjs/toolkit"
import { ICampaignProps } from "./interfaces/ICampaignStore"

const initialState: ICampaignProps = {}

const campaignsSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {}
})

export default campaignsSlice.reducer
