import { createSlice } from "@reduxjs/toolkit"
import { ICharacteristicProps } from "./interfaces/ICharacteristicStore"

const initialState: ICharacteristicProps = {}

const differentialsSlice = createSlice({
  name: "differentials",
  initialState,
  reducers: {}
})

export default differentialsSlice.reducer
