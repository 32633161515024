import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { RequestCampaignDay } from '../../../interfaces/api/request'
import { Campaign } from '../../../interfaces/campaign'

const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/Campaign` }),
  tagTypes: ['campaigns'],
  endpoints: (campaign) => ({
    getCampaignsDay: campaign.mutation<DataRTKReturnList<Campaign>, RequestCampaignDay>({
      query: (content) => ({
        url: `/get-campaign-day`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['campaigns']
    }),
  })
})

export default campaignApi
