import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IContentByStateNameId } from './interfaces/IContentApis'
import { City } from '../../../interfaces/city'
import { DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { DataRTKReturn } from '../../../interfaces/dataRTKReturn'
import { IGetByStateNameCity } from './interfaces/argsApi'

const cityApi = createApi({
  reducerPath: 'cityApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo/cities` }),
  tagTypes: ['cities'],
  endpoints: (builder) => ({
    getAllByState: builder.mutation<DataRTKReturnList<City>, string>({
      query: (uf:string) => ({
        url: `/get-all-by-state`,
        body: JSON.stringify({
          uf: uf,
          name: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['cities']
    }),
    getByStateNameCity: builder.mutation<DataRTKReturn<City>, IGetByStateNameCity>({
      query: (content:IGetByStateNameCity) => ({
        url: `/get-all-by-state`,
        body: JSON.stringify({
          uf: content.uf,
          name: content.city
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['cities']
    })
  })
})

export default cityApi
