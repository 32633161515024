import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import { ButtonHTMLAttributes, forwardRef, ReactNode, Ref } from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  asChild?: boolean
  paddingXClass?: string
  variant: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'orange'
  fontSizeClassName?: string
}

export const Button = forwardRef(
  (
    { children, asChild, className, paddingXClass="px-3.5", fontSizeClassName = 'text-base lg:text-xl', variant, ...props }: ButtonProps,
    forwardedRef: Ref<HTMLButtonElement>
  ) => {
    const Component = asChild ? Slot : 'button'

    return (
      <Component
        ref={forwardedRef}
        className={clsx(
          className,

          `py-2 ${paddingXClass} ${fontSizeClassName} font-bold rounded flex items-center justify-center gap-2.5 cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed transition-colors ease-in-out duration-250`,
          {
            'bg-blue-700 text-white hover:bg-blue-800': variant === 'primary',
            'bg-transparent border border-blue-700 text-blue-700': variant === 'secondary',
            'bg-white text-blue-700': variant === 'tertiary',
            'bg-[#F15C00] text-white' : variant === 'orange',
            'bg-transparent': variant === 'transparent'
          }
        )}
        {...props}
      >
        {children}
      </Component>
    )
  }
)
