import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
  search: string | null
  searchCityId: string | null
  searchStateId: string | null
  searchDistrictId: string | null
  statusIdList: string[] | null
  numberRooms: number | null
  priceMin: string | null
  priceMax: string | null
}

const initialState: IInitialState = {
  search: null,
  searchCityId: null,
  searchStateId: null,
  searchDistrictId: null,
  statusIdList: null,
  numberRooms: null,
  priceMin: null,
  priceMax: null
}

const filterEnterprisesSlice = createSlice({
  name: 'filterEnterprises',
  initialState,
  reducers: {
    changeStatusList: (state, action: PayloadAction<string[]>) => {
      state.statusIdList = action.payload
    },
    changeSearchState: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    changeSearchStateId: (state, action: PayloadAction<string>) => {
      state.searchStateId = action.payload
    },
    changeSearchStateCityId: (state, action: PayloadAction<string>) => {
      state.searchStateId = action.payload
    },
    changeSearchStateDistrictId: (state, action: PayloadAction<string>) => {
      state.searchDistrictId = action.payload
    },
    changeNumberRoomsState: (state, action: PayloadAction<number>) => {
      state.numberRooms = action.payload
    },
    changePriceMinState: (state, action: PayloadAction<string>) => {
      state.priceMin = action.payload
    },
    changePriceMaxState: (state, action: PayloadAction<string>) => {
      state.priceMax = action.payload
    }
  }
})

export const {
  changeSearchState,
  changeNumberRoomsState,
  changePriceMaxState,
  changePriceMinState,
  changeSearchStateCityId,
  changeSearchStateDistrictId,
  changeSearchStateId,
  changeStatusList,
} = filterEnterprisesSlice.actions
export default filterEnterprisesSlice.reducer
